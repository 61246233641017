import App from "@components/App/App"
import ForgotPassword from "@components/Auth/ForgotPassword.tsx"
import Loader from "@components/Auth/Loader.tsx"
import Login from "@components/Auth/Login.tsx"
import ResetPassword from "@components/Auth/ResetPassword.tsx"
import ValidateEmail from "@components/Auth/ValidateEmail.tsx"
import AuthContext from "@contexts/AuthContext.tsx"
import { RequestsProvider } from "@contexts/RequestsContext.tsx"
import { SelectsProvider } from "@contexts/SelectsContext.tsx"
import { ReactNode, useContext } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

const Root = () => (
  <>
    <UserLoadingTemplate>
      <Loader />
    </UserLoadingTemplate>
    <UserAuthenticatedTemplate>
      <SelectsProvider>
        <RequestsProvider>
          <App />
        </RequestsProvider>
      </SelectsProvider>
    </UserAuthenticatedTemplate>
    <UserUnAuthenticatedTemplate>
      <Routes>
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/reset-password" element={<ResetPassword />} />
        <Route path="/auth/validate-email" element={<ValidateEmail />} />
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Routes>
    </UserUnAuthenticatedTemplate>
  </>
)

export default Root

const UserAuthenticatedTemplate = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useContext(AuthContext)
  return isAuthenticated ? children : <></>
}

const UserUnAuthenticatedTemplate = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useContext(AuthContext)
  return isAuthenticated ? <></> : children
}

const UserLoadingTemplate = ({ children }: { children: ReactNode }) => {
  const { isLoading } = useContext(AuthContext)
  return isLoading ? children : <></>
}
