const processEnv = import.meta.env

const computedEnv = {
  isDevelopment: processEnv.MODE === "development",
  isStaging: processEnv.MODE === "staging",
  isProduction: processEnv.MODE === "production"
}

/**
 * Export all environment variables
 */
export const env = { ...processEnv, ...computedEnv }
