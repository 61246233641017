import ModalLayout from "@components/Auth/ModalLayout/ModalLayout"
import { faCheckCircle, faLock } from "@fortawesome/pro-light-svg-icons"
import { Button, Result } from "antd"
import { Icon } from "components/Icon"
import { Link } from "react-router-dom"

const Error500 = () => {
  return (
    <ModalLayout>
      <Result
        title="Oups !"
        subTitle={
          <>
            <p>Quelque-chose s&apos;est mal passé.</p>
            <p>Si le problème persiste, prennez contact avec le support.</p>
          </>
        }
        status="500"
        style={{ paddingTop: 20 }}
        icon={<Icon icon={faCheckCircle} />}
        extra={
          <Link to="/login">
            <Button type="primary" icon={<Icon icon={faLock} />}>
              Aller à l&apos;écran de connexion
            </Button>
          </Link>
        }
      />
    </ModalLayout>
  )
}

export default Error500
