import "./RequestsCalendar.less"

import { RequestStatusTag } from "@components/Tags"
import { RequestsContext } from "@contexts/RequestsContext.tsx"
import SelectsContext from "@contexts/SelectsContext"
import { EventClickArg, EventContentArg } from "@fullcalendar/core"
import frLocale from "@fullcalendar/core/locales/fr"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"
import FullCalendar from "@fullcalendar/react"
import { Request } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService.ts"
import { useQuery } from "@tanstack/react-query"
import { getRequestStatusColor } from "@utils/utils"
import { Spin, Tag } from "antd"
import dayjs from "dayjs"
import { useContext, useState } from "react"

const RequestsCalendar = () => {
  const { getLabel, getCareUnit } = useContext(SelectsContext)
  const { filters, openRequestDetails, openNewRequestModal } = useContext(RequestsContext)

  const [dateFilters, setDateFilters] = useState({
    start: dayjs().startOf("month").toISOString(),
    end: dayjs().endOf("month").toISOString()
  })

  const requests = useQuery({
    queryKey: ["requests", { ...filters, ...dateFilters }],
    queryFn: () => api.request.getAllRequests(1, 1000, { ...filters, ...dateFilters })
  })

  const events = requests.data?.items?.map((r) => ({
    title: r.helpTypeValue,
    date: r.start,
    allDay: true,
    borderColor: "transparent",
    extendedProps: r
  }))

  const handleEventClick = (clickInfo: EventClickArg) => {
    openRequestDetails(clickInfo.event.extendedProps as Request)
  }

  const handleDateClick = (clickInfo: DateClickArg) => {
    openNewRequestModal(clickInfo.date)
  }

  const handleChangeDate = (info: { view: { currentStart: Date; currentEnd: Date } }) => {
    const start = dayjs(info.view.currentStart).toISOString()
    const end = dayjs(info.view.currentEnd).toISOString()
    if (dateFilters.start !== start && dateFilters.end !== end) {
      setDateFilters({ start, end })
    }
  }

  const renderEventContent = (eventInfo: EventContentArg) => {
    const request = eventInfo.event.extendedProps as Request
    return (
      <div
        style={{
          padding: 7,
          borderRadius: 3,
          fontSize: 14,
          borderLeft: `4px solid ${getRequestStatusColor(request.status)}`,
          background: getRequestStatusColor(request.status, true),
          whiteSpace: "normal"
        }}
      >
        <div style={{ position: "absolute", top: 3, right: -4 }}>
          <Tag>{getCareUnit(request.careUnitId)?.code || "-"}</Tag>
          <RequestStatusTag status={request.status} onlyIcon={true} />
        </div>
        <b>
          {dayjs(request.start).format("LT")} - {dayjs(request.end).format("LT")}
        </b>
        <br />
        <i>
          {getLabel(request.qualificationValue)} - {getLabel(request.helpTypeValue)}
        </i>
      </div>
    )
  }

  return (
    <div className="requests-calendar">
      <Spin spinning={requests.isLoading}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          firstDay={1}
          locales={[frLocale]}
          locale="fr-be"
          events={events}
          eventClick={handleEventClick}
          eventContent={renderEventContent}
          eventBackgroundColor="transparent"
          eventTextColor="#000"
          dayMaxEvents={true}
          dateClick={handleDateClick}
          datesSet={handleChangeDate}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "today dayGridMonth,dayGridWeek"
          }}
        />
      </Spin>
    </div>
  )
}

export default RequestsCalendar
