import { Icon } from "@components/Icon"
import { faCircleExclamation, faTimes } from "@fortawesome/pro-light-svg-icons"
import { RejectMultipleRequestsRequest, Request } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Alert, Form, Input, message, Modal } from "antd"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

type RejectRequestsModalProps = {
  open: boolean
  requests?: Request[]
  onOK: (count: number) => void
  onCancel: () => void
}

export const RejectRequestsModal = ({ open, onOK, onCancel, requests }: RejectRequestsModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const rejectRequests = useMutation({
    mutationFn: (data: RejectMultipleRequestsRequest) => api.request.rejectMultipleRequests(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["requests"])
      queryClient.invalidateQueries(["requestsStatisticsSider"])
      queryClient.invalidateQueries(["requests-statistics"])
      requests?.forEach((r) => {
        queryClient.invalidateQueries(["request", { id: r.id }])
      })
      onOK(data.totalItems)

      message.success(t("requests.message.success.reject_requests", { count: data.totalItems }))
    }
  })

  const [form] = Form.useForm()

  useEffect(() => {
    if (open) form.resetFields()
  }, [open, form, requests])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (requests?.length) rejectRequests.mutate({ ...values, ids: requests.map((r) => r.id) })
  }

  return (
    <Modal
      title={
        <>
          <Icon icon={faCircleExclamation} fixedWidth color="orange" /> {t("requests.modal.reject_request.title", { count: requests?.length })}
        </>
      }
      className="reject-request-confirmation-modal"
      open={open}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t("requests.modal.reject_request.button.ok_text", { count: requests?.length })}
      okButtonProps={{ icon: <Icon icon={faTimes} />, loading: rejectRequests.isLoading, danger: true }}
      cancelButtonProps={{ disabled: rejectRequests.isLoading }}
      destroyOnClose={true}
    >
      <Alert message={t("requests.modal.reject_request.warning", { count: requests?.length })} style={{ marginTop: 20 }} showIcon type="warning" />
      {rejectRequests.isError && (
        <Alert
          message={t("requests.modal.reject_request.error.message", { count: requests?.length })}
          description={t("requests.modal.reject_request.error.description", { count: requests?.length })}
          type="error"
          showIcon
          style={{ marginTop: 20 }}
        />
      )}
      <Form form={form} onFinish={onFinish} layout="vertical" style={{ marginTop: 20 }}>
        <Form.Item name="comment" label={t("requests.label.comment")}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}
