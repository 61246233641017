import { Icon } from "@components/Icon"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { Result } from "antd"

import ModalLayout from "./ModalLayout/ModalLayout"

const Loader = () => (
  <ModalLayout>
    <Result title="Chargement en cours..." subTitle="Veuillez patienter une petite seconde" status="info" style={{ paddingTop: 20 }} icon={<Icon icon={faSpinnerThird} spin />} />
  </ModalLayout>
)
export default Loader
