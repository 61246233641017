import { Tag, Tooltip } from "antd"
import dayjs from "dayjs"
import { ReactNode } from "react"
import { Translation } from "react-i18next"

export enum Cascade {
  URGENT = "urgent",
  SEMIURGENT = "semiurgent",
  NORMAL = "normal",
  PLANNING = "planning"
}

export const cascadeColors = {
  [Cascade.URGENT]: "red",
  [Cascade.SEMIURGENT]: "orange",
  [Cascade.NORMAL]: "blue",
  [Cascade.PLANNING]: "green"
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getCascadeColor = (cascade: Cascade | string | undefined): string => (cascade ? cascadeColors[Cascade[cascade]] || "blue" : "blue")

export const getRequestCascade = (request: { start: Date }): Cascade => {
  if (dayjs(request.start).isBefore(dayjs().add(24, "hours"))) return Cascade.URGENT
  if (dayjs(request.start).isBefore(dayjs().add(72, "hours"))) return Cascade.SEMIURGENT
  if (dayjs(request.start).isBefore(dayjs().add(30, "days"))) return Cascade.NORMAL
  return Cascade.PLANNING
}

export const getRequestCascadeTag = (request: { start: Date }): ReactNode => {
  const cascade = getRequestCascade(request)
  return (
    <Tooltip title={<Translation>{(t) => t(`cascade.${cascade}.tooltip`)}</Translation>}>
      <Tag color={cascadeColors[cascade]}>{<Translation>{(t) => t(`cascade.${cascade}`)}</Translation>}</Tag>
    </Tooltip>
  )
}
