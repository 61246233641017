import { ResponseError } from "@opal/interimeo-openapi"
import { message } from "antd"
import { TFunction } from "i18next"

export const showMessageError = (error: ResponseError, t: TFunction, callbackFun: (json: { code: string }) => void) => {
  error.response.json().then((json: { code: string }) => {
    message.error(t(`errors.${json.code}`))

    if (callbackFun) callbackFun(json)
  })
}
