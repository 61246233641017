const french = {
  name: "french",
  startYear: 1,
  yearLength: 365,
  epoch: 1721424,
  century: 25,
  weekStartDayIndex: 2,
  getMonthLengths(isLeap: boolean) {
    return [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  },
  isLeap(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
  },
  getLeaps(currentYear: number) {
    if (currentYear === 0) return

    let year = currentYear > 0 ? 1 : -1

    const leaps = [],
      condition = () => (currentYear > 0 ? year <= currentYear : currentYear <= year),
      increase = () => (currentYear > 0 ? year++ : year--)

    while (condition()) {
      if (this.isLeap(year)) leaps.push(year)

      increase()
    }

    return leaps
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getDayOfYear({ year, month, day }) {
    const monthLengths = this.getMonthLengths(this.isLeap(year))

    for (let i = 0; i < month.index; i++) {
      day += monthLengths[i]
    }

    return day
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getAllDays(date) {
    const { year } = date

    return this.yearLength * (year - 1) + this.leapsLength(year) + this.getDayOfYear(date)
  },
  leapsLength(year: number) {
    return (((year - 1) / 4) | 0) + (-((year - 1) / 100) | 0) + (((year - 1) / 400) | 0)
  },
  guessYear(days: number, currentYear: number) {
    const year = ~~(days / 365.24)

    return year + (currentYear > 0 ? 1 : -1)
  }
}

export default french
