import { Icon } from "@components/Icon.tsx"
import { AgentsContext } from "@contexts/AgentsContext.tsx"
import { faBan, faPencil, faUserCheck } from "@fortawesome/pro-light-svg-icons"
import { Agent } from "@opal/interimeo-openapi"
import { Button, Popconfirm, Space, Tooltip } from "antd"
import { CSSProperties, MouseEvent, useContext } from "react"
import { useTranslation } from "react-i18next"

type AgentActionsMenuProps = {
  size?: "small" | "middle" | "large"
  style?: CSSProperties
  selectedAgent: Agent
}

const AgentActionsMenu = ({ size = "middle", style, selectedAgent }: AgentActionsMenuProps) => {
  const { t } = useTranslation()
  const { editAgent, disableAgent, enableAgent } = useContext(AgentsContext)

  const stopPropagation = (onClick?: () => void) => (e: MouseEvent | undefined) => {
    if (onClick) onClick()
    e?.stopPropagation()
  }

  return (
    <Space.Compact style={style} size={size}>
      {!selectedAgent.disabled && (
        <>
          <Tooltip title={t("agent.button.edit_agent")}>
            <Button icon={<Icon icon={faPencil} fixedWidth />} onClick={stopPropagation(() => editAgent(selectedAgent))} />
          </Tooltip>
          <Tooltip title={t("agent.button.disable_agent")}>
            <Button icon={<Icon icon={faBan} fixedWidth color="red" />} onClick={stopPropagation(() => disableAgent(selectedAgent))} />
          </Tooltip>
        </>
      )}
      {selectedAgent.disabled && (
        <Tooltip title={t("agent.button.enable_agent")}>
          <Popconfirm title={t("agent.popconfirm.confirm.are_you_sure")} onConfirm={stopPropagation(() => enableAgent(selectedAgent))} onCancel={stopPropagation()}>
            <Button icon={<Icon icon={faUserCheck} fixedWidth color="orange" />} onClick={stopPropagation()} />
          </Popconfirm>
        </Tooltip>
      )}
    </Space.Compact>
  )
}

export default AgentActionsMenu
