import "./App.less"

import opalLogo from "@assets/opal-logo.svg"
import AgentsMobile from "@components/Agent/AgentsMobile.tsx"
import CancelledAssignments from "@components/CancelledAssignments.tsx"
import { Icon } from "@components/Icon"
import { MenuLabelWithBadge } from "@components/MenuLabelWithBadge.tsx"
import Requests from "@components/Requests/Requests.tsx"
import { RequestsOfDay } from "@components/RequestsOfDay/RequestsOfDay.tsx"
import { RequestsToValidate } from "@components/RequestsToValidate.tsx"
import { RoleTag } from "@components/Tags.tsx"
import { AgentsProvider } from "@contexts/AgentsContext.tsx"
import AuthContext from "@contexts/AuthContext"
import SelectsContext from "@contexts/SelectsContext.tsx"
import { faCalendarDay, faChartLine, faCheckCircle, faChevronDown, faPowerOff, faRight, faUser, faUserSlash, faUsersMedical, faWarning } from "@fortawesome/pro-light-svg-icons"
import { UserRoles } from "@opal/interimeo-openapi"
import { env } from "@utils/config.ts"
import { Avatar, Button, Dropdown, Layout, Menu } from "antd"
import classNames from "classnames"
import { useContext, useMemo } from "react"
import { Translation, useTranslation } from "react-i18next"
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
// TODO import { TextInput } from '@opal/react-utils' Example imported component of the react-utils project

const { Header, Sider, Content, Footer } = Layout

const showHeaderWarning = !env.isProduction && !env.isDevelopment

const App = () => {
  const { t } = useTranslation()
  const { user, logout } = useContext(AuthContext)
  const { statisticsSider } = useContext(SelectsContext)

  const navigate = useNavigate()
  const location = useLocation()

  const menus = useMemo(
    () => ({
      [UserRoles.IC]: [
        {
          key: "/dashboard",
          icon: <Icon icon={faChartLine} />,
          label: t("app.sider.dashboard")
        },
        {
          key: "/requests-of-day",
          icon: <Icon icon={faCalendarDay} />,
          label: t("app.sider.requests_of_day")
        }
      ],
      [UserRoles.ICS]: [
        {
          key: "/dashboard",
          icon: <Icon icon={faChartLine} />,
          label: <Translation>{(t) => t("app.sider.dashboard")}</Translation>
        },
        {
          key: "/requests-to-validate",
          icon: <Icon icon={faCheckCircle} />,
          label: <MenuLabelWithBadge label={t("app.sider.requests_to_validate")} badgeProps={{ count: statisticsSider?.waiting, color: "gold" }} />
        },
        {
          key: "/requests-of-day",
          icon: <Icon icon={faCalendarDay} />,
          label: t("app.sider.requests_of_day")
        }
      ],
      [UserRoles.MOBILE]: [
        {
          key: "/dashboard",
          icon: <Icon icon={faChartLine} />,
          label: <Translation>{(t) => t("app.sider.dashboard")}</Translation>
        },
        {
          key: "/requests-to-validate",
          icon: <Icon icon={faCheckCircle} />,
          label: <MenuLabelWithBadge label={t("app.sider.requests_to_validate")} badgeProps={{ count: statisticsSider?.waiting, color: "gold" }} />
        },
        {
          key: "/requests-of-day",
          icon: <Icon icon={faCalendarDay} />,
          label: t("app.sider.requests_of_day")
        },
        {
          key: "/cancelled-assignments",
          icon: <Icon icon={faUserSlash} />,
          label: <MenuLabelWithBadge label="Affectation annulées" badgeProps={{ count: statisticsSider?.acceptedAgain, color: "blue" }} />
        },
        {
          key: "/agents-mobile",
          icon: <Icon icon={faUsersMedical} />,
          label: t("app.sider.mobile_team")
        }
      ],
      [UserRoles.INTERIM]: []
    }),
    [statisticsSider?.acceptedAgain, statisticsSider?.waiting, t]
  )

  const handleSiderMenuClick = (e: { key: string }) => navigate(e.key)

  return (
    <Layout className="app-layout">
      <Sider className="app-sider" theme="light" collapsible>
        <div className={classNames("sider-header", { "sider-header-warning": showHeaderWarning })}>
          <Link className="home-link" to={"/dashboard"}>
            <img src={opalLogo} alt="Logo Opal Solutions" />
            <span className="app-name">
              Interimeo<span className="opal-red">.</span>
            </span>
          </Link>
        </div>

        <div className="sider-content">
          <Menu theme="light" mode="inline" items={user?.roles[0] ? menus[user?.roles[0]] : []} onClick={handleSiderMenuClick} selectedKeys={[location.pathname]} />
        </div>
      </Sider>

      <Layout className="site-layout">
        <Header className={classNames("app-header", { "app-header-warning": showHeaderWarning })}>
          <div className="app-header-environment">
            {showHeaderWarning && (
              <>
                <Icon icon={faWarning} fixedWidth size="lg" style={{ marginRight: 10 }} />
                {`Environnement de ${env.MODE}`}
                <Icon icon={faWarning} fixedWidth style={{ marginLeft: 10 }} />
                {env.VITE_PROD_REDIRECT && (
                  <a href={env.VITE_PROD_REDIRECT}>
                    <Button icon={<Icon icon={faRight} />} style={{ marginLeft: 25 }}>
                      Aller en production
                    </Button>
                  </a>
                )}
              </>
            )}
          </div>

          <Dropdown
            className="user-menu"
            trigger={["hover"]}
            placement="bottomRight"
            arrow={true}
            menu={{
              items: [{ key: "logout", icon: <Icon icon={faPowerOff} />, label: t("app.header.logout"), onClick: logout }]
            }}
          >
            <div>
              <Avatar draggable={false} size={40} icon={<Icon icon={faUser} fixedWidth />} style={{ marginRight: 10 }} />
              {user?.firstname} {user?.lastname} &nbsp; {user?.roles.map((r) => <RoleTag key={r} role={r} />)} <Icon icon={faChevronDown} size="sm" />
            </div>
          </Dropdown>
        </Header>

        <Content>
          <div className="main-content-wrapper">
            <Routes>
              <Route path="/dashboard" element={<Requests />} />
              <Route path="/requests-to-validate" element={<RequestsToValidate />} />
              <Route path="/requests-of-day" element={<RequestsOfDay />} />
              <Route path="/cancelled-assignments" element={<CancelledAssignments />} />
              <Route
                path="/agents-mobile"
                element={
                  <AgentsProvider>
                    <AgentsMobile />
                  </AgentsProvider>
                }
              />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </div>
          <Footer className="app-footer">
            &copy; {new Date().getFullYear()} -{" "}
            <a href="https://opalsolutions.be/" target="_blank" rel="noreferrer" className="opal-logo-text">
              <img src={opalLogo} alt="Opal Solutions logo" /> Opal Solutions{" "}
            </a>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
}

export default App
