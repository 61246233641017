import "./NewRequestModal.less"

import { Icon, InfoIcon } from "@components/Icon"
import { TableButton } from "@components/TableButton"
import { CareUnitTag } from "@components/Tags.tsx"
import AuthContext from "@contexts/AuthContext.tsx"
import { RequestsContext } from "@contexts/RequestsContext.tsx"
import SelectsContext from "@contexts/SelectsContext"
import { faCalendarCirclePlus, faPaperPlane, faPlay, faPlus, faSave, faTrash } from "@fortawesome/pro-light-svg-icons"
import { CustomShiftsType } from "@modules/CustomShiftsType"
import { FormValuesType } from "@modules/FormValuesType"
import french from "@modules/multidatepicker/calendar/french.ts"
import french_fr from "@modules/multidatepicker/locales/french_fr.ts"
import { MetadataShiftType } from "@modules/selectOption"
import { CareUnitShort, RequestCreate } from "@opal/interimeo-openapi"
import { getRequestCascade, getRequestCascadeTag } from "@services/cascadeService.tsx"
import { api } from "@services/fetchService"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { handleChangeCareUnit, handleChangeService, handleChangeShifts } from "@utils/requestFormUtils.ts"
import { getDateTimeBySelectOption, getTimeByShift, isIC } from "@utils/utils.ts"
import { Button, Col, Divider, Form, Input, InputNumber, message, Modal, Popconfirm, Radio, Row, Select, Space, Table, TimePicker, Tooltip } from "antd"
import dayjs from "dayjs"
import { omit, sortBy } from "lodash"
import { useContext, useEffect, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import MultipleDatePicker, { DateObject } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"

const { Column } = Table

type NewRequestModalProps = {
  open: boolean
  initialDate?: Date
  onOK: () => void
  onCancel: () => void
}

export const NewRequestModal = ({ open, onOK, onCancel, initialDate }: NewRequestModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [useCustomSchedule, setUseCustomSchedule] = useState(false)
  const { requestsToInsert, setRequestsToInsert } = useContext(RequestsContext)

  const insertRequestsMutation = useMutation({
    mutationFn: (data: { requests: RequestCreate[]; agencyId?: number | null }) => api.request.insertRequests(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["requests"])
      queryClient.invalidateQueries(["requests-statistics"])
      message.success(t("requests.message.success.new_requests", { count: data.length }))
      setRequestsToInsert([])
      onOK()
    }
  })

  const { getSelectOption, getLabel, shifts, qualifications, helpTypes, services, getCareUnit, agencies } = useContext(SelectsContext)
  const { user } = useContext(AuthContext)

  const [form] = Form.useForm()
  const [careUnits, setCareUnits] = useState<CareUnitShort[]>([])
  const [dates, setDates] = useState<DateObject | DateObject[] | null>([])
  const [agencyId, setAgencyId] = useState<number | null>(null)

  const agenciesToManualSend = useMemo(() => agencies?.filter((a) => a.manualSend) || [], [agencies])

  useEffect(() => {
    if (open) {
      form.resetFields()
      setUseCustomSchedule(false)
      // Pre-fill connected user's careUnit
      const careUnit = user?.careUnits?.[0]
      if (careUnit) {
        form.setFieldsValue({ serviceId: careUnit.serviceId, careUnitId: careUnit.id })
        const service = services?.find((s) => s.id === careUnit.serviceId)
        if (service) setCareUnits(service.careUnits)
        if (careUnit.costCenter.length > 0) form.setFieldValue("costCenter", careUnit.costCenter[0])
      }
    } else {
      setCareUnits([])
    }

    if (open && initialDate) form.setFieldValue("date", dayjs(initialDate))
  }, [open, form, initialDate, user, services])

  useEffect(() => {
    if (!requestsToInsert?.length) {
      setAgencyId(null)
    }
  }, [requestsToInsert])

  const addRequestToInsert = (formValues: FormValuesType) => {
    const dates = formValues.dates

    if (typeof dates !== "undefined") {
      const newRequests = []

      for (const d of dates) {
        const valuesClone = { ...formValues }
        const date = dayjs(d.toDate())
        const selectOption = getSelectOption(formValues.shiftValue)
        const times: CustomShiftsType = {
          start: valuesClone.timeStart,
          end: valuesClone.timeEnd
        }

        omit(valuesClone, ["date", "timeStart", "timeEnd"])

        const newRequest: RequestCreate = {
          ...valuesClone,
          id: date.valueOf() + Math.random() * 12,
          start: getDateTimeBySelectOption(date, selectOption, times, "start"),
          end: getDateTimeBySelectOption(date, selectOption, times, "end")
        }

        newRequests.push(newRequest)
      }

      if (typeof requestsToInsert !== "undefined") {
        setRequestsToInsert(sortBy(requestsToInsert.concat(newRequests), "start"))
      }
    }
  }

  const removeRequestToInsert = (id?: number) => {
    if (id) {
      setRequestsToInsert(requestsToInsert?.filter((r) => r.id !== id))
    } else {
      setRequestsToInsert([])
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onValuesChange = (changedValues: any, allValues: any) => {
    if ((changedValues.shiftValue || changedValues.timeStart || changedValues.timeEnd || changedValues.dates) && allValues.dates?.length) {
      const shift = allValues.shiftValue !== "custom_shift" ? getSelectOption(allValues.shiftValue) : undefined
      const customTimes = {
        start: allValues.timeStart,
        end: allValues.timeEnd
      }
      const start = getDateTimeBySelectOption(dayjs(allValues.dates[0].toDate()), shift, customTimes, "start")
      const end = getDateTimeBySelectOption(dayjs(allValues.dates[0].toDate()), shift, customTimes, "end")
      const hours = dayjs(end).diff(start, "hours", true)
      if (hours <= 7) form.setFieldValue("timeOff", 0)
      if (hours > 7) form.setFieldValue("timeOff", 30)
      if (hours >= 11) form.setFieldValue("timeOff", 60)
    }
  }

  const insertRequests = () => requestsToInsert?.length && insertRequestsMutation.mutate({ requests: requestsToInsert, agencyId })

  return (
    <Modal
      title={
        <>
          <Icon icon={faPlus} fixedWidth /> {t("requests.title.new_requests")}
        </>
      }
      className="new-request-modal"
      open={open}
      maskClosable={false}
      footer={null}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Enregistrer"
      okButtonProps={{ icon: <Icon icon={faSave} />, loading: insertRequestsMutation.isLoading }}
      width={1200}
      destroyOnClose={true}
    >
      <Row>
        <Col flex="1">
          <Form
            form={form}
            layout="horizontal"
            onFinish={addRequestToInsert}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{ marginTop: 20 }}
            requiredMark={false}
            labelWrap
            onValuesChange={onValuesChange}
          >
            <Form.Item
              name="serviceId"
              label={
                <>
                  {t("requests.label.service")}&nbsp;
                  <InfoIcon tooltip={t("requests.label.service.before.tooltip")} />
                  &nbsp;
                </>
              }
              rules={[{ required: true }]}
            >
              <Select onChange={(value) => handleChangeService(value, services, form, setCareUnits)}>
                {services?.map((s) => (
                  <Select.Option key={s.id} value={s.id}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              hidden={careUnits.length < 1}
              name="careUnitId"
              label={
                <>
                  {t("requests.label.care_unit")}&nbsp;
                  <InfoIcon tooltip={t("requests.label.care_unit.before.tooltip")} />
                  &nbsp;
                </>
              }
              rules={[{ required: true }]}
            >
              <Select onChange={(value) => handleChangeCareUnit(value, careUnits, form)}>
                {careUnits?.map((s) => (
                  <Select.Option key={s.id} value={s.id}>
                    {s.code} {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              hidden
              name="costCenter"
              label={
                <>
                  {t("requests.label.cost_center")}&nbsp;
                  <InfoIcon tooltip={t("requests.label.cost_center.before.tooltip")} />
                  &nbsp;
                </>
              }
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="dates" label={t("requests.label.date.many")} rules={[{ required: true }]}>
              <MultipleDatePicker
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                calendar={french}
                locale={french_fr}
                inputClass="antpicker-input"
                multiple
                value={dates}
                format="DD/MM/YYYY"
                sort
                onChange={setDates}
                plugins={[<DatePanel key={0} />]}
                style={{ width: "100%" }}
                editable={false}
              />
            </Form.Item>

            <Form.Item name="shiftValue" label={t("requests.label.schedule")} rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid" onChange={(event) => handleChangeShifts(event, setUseCustomSchedule)}>
                {shifts?.map((s) => (
                  <Radio.Button key={s.value} value={s.value}>
                    {getTimeByShift(s.metadata as MetadataShiftType)}
                  </Radio.Button>
                ))}

                <Tooltip title={t("requests.button.custom_schedule")}>
                  <Radio.Button key="custom_schedule" value="custom_schedule">
                    <Icon icon={faCalendarCirclePlus} />
                  </Radio.Button>
                </Tooltip>
              </Radio.Group>
            </Form.Item>

            <Form.Item label={t("requests.label.custom_schedule")} hidden={!useCustomSchedule}>
              <Form.Item
                name="timeStart"
                rules={[{ required: useCustomSchedule, message: t("requests.required.message.the_field_is_required", { field: t("requests.placeholder.start_schedule") }) }]}
                noStyle
              >
                <TimePicker
                  showSecond={false}
                  format={"HH:mm"}
                  placeholder={t("requests.placeholder.start_schedule")}
                  minuteStep={5}
                  disabled={!useCustomSchedule}
                  style={{ width: "38%" }}
                  changeOnBlur
                />
              </Form.Item>
              &nbsp;-&nbsp;
              <Form.Item
                name="timeEnd"
                rules={[{ required: useCustomSchedule, message: t("requests.required.message.the_field_is_required", { field: t("requests.placeholder.end_schedule") }) }]}
                noStyle
              >
                <TimePicker
                  showSecond={false}
                  format={"HH:mm"}
                  placeholder={t("requests.placeholder.end_schedule")}
                  minuteStep={5}
                  disabled={!useCustomSchedule}
                  style={{ width: "38%" }}
                  changeOnBlur
                />
              </Form.Item>
            </Form.Item>

            <Form.Item label={t("requests.label.time_off")}>
              <Form.Item
                name="timeOff"
                rules={[{ required: true, message: t("requests.required.message.the_field_is_required", { field: t("requests.label.time_off") }) }]}
                initialValue="0"
                noStyle
              >
                <InputNumber min={0} step={5} />
              </Form.Item>
              &nbsp;({t("requests.label.time_off.minutes")})
            </Form.Item>

            <Form.Item name="helpTypeValue" label={t("requests.label.type")} rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid">
                {helpTypes?.map((s) => (
                  <Radio.Button key={s.value} value={s.value}>
                    {s.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item name="qualificationValue" label={t("requests.label.qualification")} rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid">
                {qualifications?.map((s) => (
                  <Radio.Button key={s.value} value={s.value}>
                    {s.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item name="comment" label={t("requests.label.comment.private")}>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>

            <Form.Item
              name="commentPublic"
              label={
                <>
                  {t("requests.label.comment.public")} <InfoIcon tooltip={t("requests.label.comment.public.tooltip")} />
                </>
              }
            >
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>

            <Row justify="end">
              <Col>
                <Form.Item noStyle>
                  <Button type="primary" htmlType="submit" icon={<Icon icon={faPlus} />}>
                    {t("requests.button.add_to_list")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col>
          <Divider type="vertical" style={{ height: "100%", margin: "0 25px" }} />
        </Col>
        <Col flex="1" className="requests-to-insert-column">
          {!!requestsToInsert?.length || (
            <div style={{ textAlign: "center", color: "rgba(0,0,0,.25)", marginTop: 100 }}>
              {t("requests.modal.new_request.requests_to_insert.sentence.1")}
              <br />
              {t("requests.modal.new_request.requests_to_insert.sentence.2")}
              <br />
              ***
              <br />
              {t("requests.modal.new_request.requests_to_insert.sentence.3")}
              <br />
              ***
              <br />
              {t("requests.modal.new_request.requests_to_insert.sentence.4")}
            </div>
          )}
          {!!requestsToInsert?.length && (
            <Table
              size="small"
              dataSource={requestsToInsert}
              rowKey="id"
              className="requests-to-insert-table"
              pagination={false}
              rowClassName={(record) => "cascade-" + getRequestCascade(record)}
            >
              <Column render={getRequestCascadeTag} />
              <Column title={t("requests.label.care_unit")} dataIndex="careUnitId" render={(careUnitId) => <CareUnitTag careUnit={getCareUnit(careUnitId)} />} />
              <Column title={t("requests.label.date")} render={(requestsToInsert) => dayjs(requestsToInsert.start).format("DD/MM/YY")} />
              <Column
                title={t("requests.label.schedule")}
                render={(requestsToInsert) => `${dayjs(requestsToInsert.start).format("LT")} - ${dayjs(requestsToInsert.end).format("LT")}`}
                width="100px"
              />
              <Column title={t("requests.label.type")} dataIndex="helpTypeValue" render={getLabel} />
              <Column title={t("requests.label.qualification")} dataIndex="qualificationValue" render={getLabel} />
              <Column render={(record) => <TableButton icon={faTrash} onClick={() => removeRequestToInsert(record.id)} type="danger" />} />
            </Table>
          )}
          <Row justify="space-between" style={{ marginTop: 15 }}>
            <Col>
              <Popconfirm
                title={
                  <Trans i18nKey="requests.modal.new_request.clear_list">
                    Êtes-vous sûr ?<br />
                    Toutes les données seront perdues.
                  </Trans>
                }
                onConfirm={() => removeRequestToInsert()}
                disabled={!requestsToInsert?.length}
              >
                <Button icon={<Icon icon={faTrash} />} disabled={!requestsToInsert?.length}>
                  {t("requests.button.clear_list")}
                </Button>
              </Popconfirm>
            </Col>
            <Col>
              <Space direction="vertical" align="end">
                <Space>
                  {user && !isIC(user) && (
                    <Select
                      value={agencyId}
                      placeholder="Envoyer à une agence ?"
                      suffixIcon={<Icon icon={faPaperPlane} />}
                      onChange={setAgencyId}
                      allowClear
                      disabled={!requestsToInsert?.length}
                      style={{ width: "195px" }}
                    >
                      {agenciesToManualSend?.map((a) => (
                        <Select.Option key={a.id} value={a.id}>
                          {a.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                  <Popconfirm title={t("requests.message.success.will_be_created_requests", { count: requestsToInsert?.length })} onConfirm={insertRequests}>
                    {Number.isInteger(agencyId) ? (
                      <Button type="primary" icon={<Icon icon={faPaperPlane} />} disabled={!requestsToInsert?.length}>
                        {t("requests.button.register_and_send")}
                      </Button>
                    ) : (
                      <Button type="primary" icon={<Icon icon={faSave} />} disabled={!requestsToInsert?.length}>
                        {t("requests.button.register")}
                      </Button>
                    )}
                  </Popconfirm>
                </Space>
                <Popconfirm title={t("misc.confirm.are_you_sure")} onConfirm={insertRequests}>
                  <Button type="primary" loading={insertRequestsMutation.isLoading} icon={<Icon icon={faPlay} fixedWidth />} disabled>
                    {t("requests.button.add_requests_and_send_interim_cascade")}
                  </Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}
