import "./ModalLayout.less"

import logo from "@assets/opal-logo-blanc-long.png"
import logo_background2 from "@assets/opal-pattern-1-bg.png"
import logo_background from "@assets/opal-pattern-2b-bg.png"
import { CSSProperties, ReactNode } from "react"

type ModalLayoutProps = {
  children: ReactNode
  width?: number
  title?: string
  style?: CSSProperties
}

const ModalLayout = ({ children, width = 420, title, style = {} }: ModalLayoutProps) => (
  <div className="modal-layout-container">
    <div className="modal-layout-background">
      <img src={logo_background} alt="Logo FC arrière plan" />
      <img src={logo_background2} alt="Logo FC arrière plan" />
    </div>
    <div className="modal-layout-data" style={{ maxWidth: width }}>
      <div className="modal-layout-header">
        <img className="logo" src={logo} alt="Logo formation continue" />
      </div>
      <div className="modal-layout-header-border">
        <div style={{ backgroundColor: "#1c63bd" }} />
        <div style={{ backgroundColor: "#159638" }} />
        <div style={{ backgroundColor: "#a9a9aa" }} />
        <div style={{ backgroundColor: "#db4f25" }} />
        <div style={{ backgroundColor: "#ff9536" }} />
      </div>
      <div className="modal-layout-content" style={style}>
        {title && <h1>{title}</h1>}
        {children}
      </div>
    </div>
  </div>
)

export default ModalLayout
