import ModalLayout from "@components/Auth/ModalLayout/ModalLayout"
import { Icon } from "@components/Icon"
import { faCheckCircle, faExclamationTriangle, faInfoCircle, faLock, faPaperPlane, faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { api } from "@services/fetchService.ts"
import { Button, Result } from "antd"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useInterval } from "react-use"

import Error500 from "./Error500"

enum Errors {
  INVALID_CODE,
  UNKNOWN
}

const ValidateEmail = () => {
  const [isSent, setIsSent] = useState(false)
  const [isValidated, setIsValidated] = useState(false)
  const [error, setError] = useState<Errors>()
  const [timeBeforeClose, setTimeBeforeClose] = useState(5)

  const params = new URLSearchParams(useLocation().search)
  const email = params.get("email")
  const code = params.get("code")

  useInterval(
    () => {
      if (timeBeforeClose <= 1) window.close()
      setTimeBeforeClose(timeBeforeClose - 1)
    },
    isValidated ? 1000 : null
  )

  useEffect(() => {
    if (code) {
      api.auth
        .validateEmail({ code })
        .then(() => {
          setTimeout(() => setIsValidated(true), 1000)
        })
        .catch((error) => {
          // TODO check is work !!!
          if (error.response?.json?.code === "auth.invalid_or_expired_code") {
            setTimeout(() => setError(Errors.INVALID_CODE), 1000)
          } else {
            setTimeout(() => setError(Errors.UNKNOWN), 1000)
          }
        })
    }
  }, [code])

  if ((!email && !code) || (email && code) || error === Errors.UNKNOWN) {
    return <Error500 />
  }

  const send = () => {
    if (email) api.auth.sendValidationEmail({ email }).then(() => setIsSent(true))
  }

  return (
    <ModalLayout>
      {error === Errors.INVALID_CODE && (
        <Result
          title="Oups..."
          subTitle="Le lien que vous avez utilisé semble périmé ou déjà utilisé. Essayez de vous connecter à nouveau pour obtenir un nouveau lien."
          status="warning"
          style={{ paddingTop: 20 }}
          icon={<Icon icon={faExclamationTriangle} />}
          extra={
            <Link to="/login">
              <Button type="primary" icon={<Icon icon={faLock} />}>
                Aller à l&apos;écran de connexion
              </Button>
            </Link>
          }
        />
      )}

      {code &&
        !error &&
        (isValidated ? (
          <Result
            title="L'adresse e-mail est validée !"
            subTitle={
              <>
                Cette page se fermera automatiquement dans <strong>{timeBeforeClose}</strong> secondes...
              </>
            }
            status="success"
            style={{ paddingTop: 20 }}
            icon={<Icon icon={faCheckCircle} />}
          />
        ) : (
          <Result
            title="Veuillez patienter..."
            subTitle="Nous vérifions que tout est en ordre..."
            status="info"
            style={{ paddingTop: 20 }}
            icon={<Icon icon={faSpinnerThird} spin />}
            extra={
              <Button type="primary" icon={<Icon icon={faPaperPlane} />} onClick={send}>
                Renvoyer l&apos;e-mail
              </Button>
            }
          />
        ))}

      {email &&
        (isSent ? (
          <Result
            title="C'est fait !"
            subTitle="Consultez votre boite mail, et cliquez sur le lien que nous vous avons envoyé pour valider votre adresse e-mail."
            status="success"
            style={{ paddingTop: 20 }}
            icon={<Icon icon={faCheckCircle} />}
            extra={
              <Link to="/login">
                <Button type="primary" icon={<Icon icon={faLock} />}>
                  Aller à l&apos;écran de connexion
                </Button>
              </Link>
            }
          />
        ) : (
          <Result
            title="Vous y êtes presque !"
            subTitle={
              <>
                <p>Il ne reste plus qu&apos;à valider votre adresse e-mail. Pour cela, cliquez sur le lien dans le mail que vous avez reçu.</p>
                <p>Vous ne l&apos;avez pas reçu ? Utilisez le bouton ci-dessous pour renvoyer un nouveau mail.</p>
              </>
            }
            status="info"
            style={{ paddingTop: 20 }}
            icon={<Icon icon={faInfoCircle} />}
            extra={
              <Button type="primary" icon={<Icon icon={faPaperPlane} />} onClick={send}>
                Renvoyer l&apos;e-mail
              </Button>
            }
          />
        ))}
    </ModalLayout>
  )
}

export default ValidateEmail
