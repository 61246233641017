import "./AgentModal.less"

import { Icon } from "@components/Icon.tsx"
import { faBan, faTrash } from "@fortawesome/pro-light-svg-icons"
import { Agent, GetAllRequests200Response } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService.ts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Alert, Form, message, Modal } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

type DisableAgentModalProps = {
  open: boolean
  onOK: () => void
  onCancel: () => void
  agent?: Agent
}

export const DisableAgentModal = ({ open, onOK, onCancel, agent }: DisableAgentModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [requestsByAgent, setRequestsByAgent] = useState<GetAllRequests200Response | null>(null)
  const [currentAgent, setCurrentAgent] = useState<Agent>()

  const requestsByAgentMutation = useMutation({
    mutationFn: (agentId: number) => {
      return api.request.getAllRequests(1, 1000, { agentId, start: dayjs().toISOString() })
    },
    onSuccess: (data) => {
      setRequestsByAgent(data)
    }
  })

  const disableAgent = useMutation({
    mutationFn: (data: { id: number }) => {
      return api.agent.disableAgent(data.id, { disableAndCancelAvailabilities: true })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["agents"])
      queryClient.invalidateQueries(["requestsStatisticsSider"])
      message.success(t("agent.message.success.disabled_agent"))
      onOK()
    }
  })

  const [form] = Form.useForm()

  useEffect(() => {
    if (open && agent && currentAgent !== agent) {
      form.resetFields()
      setCurrentAgent(agent)
      requestsByAgentMutation.mutate(agent.id)
    }
  }, [open, form, agent, currentAgent, requestsByAgent, requestsByAgentMutation])

  const save = () => {
    agent && disableAgent.mutate({ id: agent.id })
  }

  return (
    <Modal
      title={
        <>
          <Icon icon={faBan} fixedWidth color="orange" /> {t("agent.modal.disable_agent.title")}
        </>
      }
      className="agent-modal"
      open={open}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t("agent.button.disable")}
      okButtonProps={{ icon: <Icon icon={faTrash} />, danger: true }}
      width={600}
      destroyOnClose={true}
    >
      <Form form={form} layout="horizontal" onFinish={save} style={{ marginTop: 30 }} requiredMark={false} labelWrap>
        {requestsByAgent && requestsByAgent.totalItems > 0 && (
          <Alert message={t("agent.modal.disable_agent.availability.warning", { count: requestsByAgent.totalItems })} showIcon type="warning" />
        )}
        {requestsByAgent && requestsByAgent.totalItems === 0 && (
          <Alert message={t("agent.modal.disable_agent.warning", { count: requestsByAgent.totalItems })} showIcon type="warning" />
        )}
      </Form>
    </Modal>
  )
}
