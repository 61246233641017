import "./AgentModal.less"

import { Icon } from "@components/Icon.tsx"
import { AgentsContext } from "@contexts/AgentsContext.tsx"
import SelectsContext from "@contexts/SelectsContext.tsx"
import { faPencil } from "@fortawesome/pro-light-svg-icons"
import { Agent, AgentUpdate } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService.ts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Form, Input, message, Modal, Radio } from "antd"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"

type UpdateAgentModalProps = {
  open: boolean
  onOK: (agent: Agent) => void
  onCancel: () => void
  agent?: Agent
}

export const UpdateAgentModal = ({ open, onOK, onCancel, agent }: UpdateAgentModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { filters } = useContext(AgentsContext)
  const { qualifications } = useContext(SelectsContext)

  const updateAgent = useMutation({
    mutationFn: (data: { id: number; agent: AgentUpdate }) => {
      return api.agent.updateAgent(data.id, data.agent)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["agents"])
      queryClient.invalidateQueries(["requestsStatisticsSider"])
      message.success(t("agent.message.success.update_agent"))
      onOK(data)
    }
  })

  const [form] = Form.useForm()

  useEffect(() => {
    if (open) form.resetFields()
    if (open && agent) {
      form.setFieldsValue({ ...agent })
    }
  }, [open, form, agent])

  const save = (formValues: AgentUpdate) => {
    const valuesClone = { ...formValues }

    valuesClone.type = filters.type

    agent && updateAgent.mutate({ id: agent.id, agent: valuesClone })
  }

  return (
    <Modal
      title={
        <>
          <Icon icon={faPencil} fixedWidth color="orange" /> {t("agent.modal.edit_agent.title")}
        </>
      }
      className="agent-modal"
      open={open}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t("agent.button.register")}
      width={600}
      destroyOnClose={true}
    >
      <Form form={form} layout="horizontal" onFinish={save} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} style={{ marginTop: 30 }} requiredMark={false} labelWrap>
        <Form.Item name="firstname" label={t("agent.label.firstname")} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="lastname" label={t("agent.label.lastname")} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label={t("agent.label.email")} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="qualificationValue" label={t("agent.label.qualificationValue")} rules={[{ required: true }]} style={{ marginBottom: 0 }}>
          <Radio.Group buttonStyle="solid">
            {qualifications?.map((s) => (
              <Radio.Button key={s.value} value={s.value}>
                {s.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}
