import { Badge, BadgeProps, Col, Row } from "antd"
import { ReactNode } from "react"

type MenuLabelWithBadgeProps = {
  label: ReactNode | string
  badge?: ReactNode
  badgeProps?: BadgeProps
}
export const MenuLabelWithBadge = ({ label, badge, badgeProps }: MenuLabelWithBadgeProps) => (
  <>
    <Row justify="space-between">
      <Col>{label}</Col>
      <Col>{badge ? badge : <Badge {...badgeProps} />}</Col>
    </Row>
  </>
)
