import { User } from "@opal/interimeo-openapi"
import * as fetchService from "@services/fetchService"
import { api } from "@services/fetchService"
import { useMutation } from "@tanstack/react-query"
import { createContext, useEffect } from "react"
import { useMap } from "react-use"

interface IAuthContext {
  isAuthenticated: boolean
  user?: User
  token?: string
  isLoading: boolean
  login: (login: string, password: string) => Promise<User>
  logout: () => void
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = createContext<IAuthContext>(null!)

interface AuthProviderProps {
  children: React.ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, { set: setAuth, setAll: setAllAuth }] = useMap<{
    isAuthenticated: boolean
    user?: User
    token?: string | null
    isLoading: boolean
    isRecovering: boolean
  }>()

  const loginMutation = useMutation({
    mutationFn: (body: { email: string; password: string }) => api.auth.login(body)
  })

  const login = (email: string, password: string) => {
    setAuth("isLoading", true)
    return new Promise<User>((resolve, reject) => {
      loginMutation.mutate(
        { email, password },
        {
          onSuccess: (data) => {
            localStorage.setItem("token", data.token)
            fetchService.setAccessToken(data.token)
            setAllAuth({ isAuthenticated: true, isLoading: false, token: data.token, user: data.user, isRecovering: false })
            resolve(data.user)
          },
          onSettled: (_data, error) => {
            setAuth("isLoading", false)
            reject(error)
          }
        }
      )
    })
  }

  const logout = () => {
    localStorage.removeItem("token")
    fetchService.setAccessToken("")
    setAllAuth({ isAuthenticated: false, isLoading: false, token: undefined, user: undefined, isRecovering: false })
  }

  const recover = () => {
    // Check if token exists in localstorage
    let token = localStorage.getItem("token")
    // Check if token exists in sessionStorage
    if (!token) {
      token = sessionStorage.getItem("token")
    }
    // If no token, stop recovering
    if (!token) {
      return
    }
    // Get user from DB
    setAuth("isLoading", true)
    setAuth("isRecovering", true)
    fetchService.setAccessToken(token)
    fetchService.api.auth
      .getUser()
      .then((user) => {
        setAllAuth({ isAuthenticated: true, isLoading: false, token, user, isRecovering: false })
      })
      .catch(logout)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(recover, [])

  const exposedValue: IAuthContext = {
    isAuthenticated: auth.isAuthenticated,
    user: auth.user,
    token: auth.token || undefined,
    isLoading: auth.isLoading,
    login,
    logout
  }

  return <AuthContext.Provider value={exposedValue}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
export default AuthContext
