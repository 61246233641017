const french_fr = {
  name: "french_fr",
  months: [
    ["Janvier", "jan"],
    ["Février", "feb"],
    ["Mars", "mar"],
    ["Avril", "apr"],
    ["Mai", "may"],
    ["Juin", "jun"],
    ["Juillet", "jul"],
    ["Août", "aug"],
    ["Septembre", "sep"],
    ["Octobre", "oct"],
    ["Novembre", "nov"],
    ["Décembre", "dec"]
  ],
  weekDays: [
    ["Samedi", "sam"],
    ["Dimanche", "dim"],
    ["Lundi", "lun"],
    ["Mardi", "mar"],
    ["Mercredi", "mer"],
    ["Jeudi", "jeu"],
    ["Vendredi", "ven"]
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"]
  ]
}

export default french_fr
