import { BooleanIcon, Icon } from "@components/Icon.tsx"
import RequestActionsMenu from "@components/RequestActionsMenu.tsx"
import RequestFilters from "@components/Requests/RequestFilters.tsx"
import { AgentInfo } from "@components/RequestsList/RequestsList.tsx"
import { AssignAvailabilityModal } from "@components/RequestsOfDay/AssignAvailabilityModal.tsx"
import { CareUnitTag, RequestStatusTag } from "@components/Tags.tsx"
import AuthContext from "@contexts/AuthContext.tsx"
import { RequestsContext } from "@contexts/RequestsContext.tsx"
import SelectsContext from "@contexts/SelectsContext.tsx"
import { DndContext, DragEndEvent, useDraggable, useDroppable } from "@dnd-kit/core"
import { CSS } from "@dnd-kit/utilities"
import { faCalendarDay, faCheck, faChevronLeft, faChevronRight, faGripLines, faPaperPlane, faPipe, faPlus, faTimes } from "@fortawesome/pro-light-svg-icons"
import { AvailabilityRelations, RequestRelations, RequestStatus } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService.ts"
import { useQuery } from "@tanstack/react-query"
import { fullnameAgent, isICS, isMobile } from "@utils/utils.ts"
import { Button, Card, Col, DatePicker, Row, Space, Switch, Table, Tooltip, Typography } from "antd"
import { TablePaginationConfig } from "antd/es/table"
import classNames from "classnames"
import dayjs from "dayjs"
import { Children, cloneElement, ReactElement, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMap } from "react-use"

const { Title } = Typography
const { Column } = Table

/**
 * Drag and drop interfaces and components
 */
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string
  "data-row-disabled": boolean
}

const RequestRow = (props: RowProps) => {
  const { attributes, listeners, setNodeRef, transform, isDragging, setActivatorNodeRef, node } = useDraggable({
    id: props["data-row-key"]
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && { x: transform.x - (node?.current?.offsetWidth || 0) * 0.1 + 15, y: transform.y - (node?.current?.offsetHeight || 0) * 0.1 + 4, scaleX: 0.8, scaleY: 0.8 }
    ),
    ...(isDragging ? { position: "relative", zIndex: 999 } : {})
  }

  if (props["data-row-disabled"]) {
    return <tr {...props} />
  }
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {Children.map(props.children, (child) => {
        if ((child as ReactElement).key === "dnd-handle") {
          return cloneElement(child as ReactElement, {
            children: (
              <div ref={setActivatorNodeRef} style={{ touchAction: "none", cursor: "move" }} {...listeners}>
                <Icon icon={faGripLines} fixedWidth />
              </div>
            )
          })
        }
        return child
      })}
    </tr>
  )
}

const AvailabilityRow = (props: RowProps) => {
  const { isOver, setNodeRef } = useDroppable({
    id: props["data-row-key"]
  })

  const style: React.CSSProperties = {
    ...props.style,
    ...(isOver ? { backgroundColor: "rgb(250, 250, 250)" } : {})
  }

  return <tr {...props} ref={setNodeRef} style={style} />
}

/**
 * Requests Of Day
 */
export const RequestsOfDay = () => {
  const { t } = useTranslation()

  const { getLabel, getCareUnit, getServiceName } = useContext(SelectsContext)
  const { user } = useContext(AuthContext)
  const { filters, openRequestDetails, openNewRequestModal, selectedRequests, setSelectedRequests } = useContext(RequestsContext)

  const [requestToAssign, setRequestToAssign] = useState<RequestRelations>()
  const [availabiltyToAssign, setAvailabiltyToAssign] = useState<AvailabilityRelations>()
  const [dayToView, setDayToView] = useState(dayjs())

  const [pagination, { set: setPagination }] = useMap<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    hideOnSinglePage: true
  })

  // Reset current pagination to 1 when filters change
  useEffect(() => {
    setPagination("current", 1)
  }, [filters, setPagination])

  const { data: requests, isLoading: isRequestsLoading } = useQuery({
    queryKey: ["requests", { pagination: { current: pagination.current, pagesize: pagination.pageSize }, filters, dayToView }],
    queryFn: () =>
      api.request.getAllRequests(pagination.current, pagination.pageSize, { ...filters, start: dayToView.startOf("day").toISOString(), end: dayToView.endOf("day").toISOString() })
  })

  const { data: availabilities, isLoading: isAvailabilitiesLoading } = useQuery({
    queryKey: ["availabilities", { dayToView }, { user }],
    queryFn: () => {
      if (user && isMobile(user)) {
        return api.availability.getAllAvailabilities({ start: dayToView.startOf("day").toISOString(), end: dayToView.endOf("day").toISOString() })
      }

      return Promise.resolve({ data: [], isLoading: false }) as unknown as Promise<AvailabilityRelations[]>
    }
  })

  const [isCancelledShown, setIsCancelledShown] = useState(false)

  const requestsToShow = isCancelledShown ? requests?.items : requests?.items.filter((r) => r.status !== RequestStatus.CANCELLED && r.status !== RequestStatus.REJECTED)
  const availabilitiesToShow = availabilities && Array.isArray(availabilities) ? availabilities?.filter((a) => !a.request && !a.canceledAt) : []

  // Set pagination total items when data changes
  useEffect(() => {
    if (pagination.total !== requests?.totalItems && requests?.totalItems !== undefined) {
      setPagination("total", requests.totalItems)
    }
  }, [requests?.totalItems, pagination, setPagination, setSelectedRequests])

  // Loose selection when filters or pagination change
  useEffect(() => setSelectedRequests([]), [filters, pagination, setSelectedRequests])

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    if (pagination.current !== newPagination.current) setPagination("current", newPagination.current)
    if (pagination.pageSize !== newPagination.pageSize) setPagination("pageSize", newPagination.pageSize)
  }

  const handleSelectChange = (_selectedRowKeys: React.Key[], selectedRows: RequestRelations[]) => {
    setSelectedRequests(selectedRows)
  }

  const getRequestById = (id: number) => requests?.items.find((r) => r.id === id)
  const getAvailabilityById = (id: number) => availabilities?.find((a) => a.id === id)

  const handleDragEnd = (e: DragEndEvent) => {
    const request = getRequestById(e.active.id as number)
    const availability = getAvailabilityById(e.over?.id as number)
    if (!request || !availability) return
    setRequestToAssign(request)
    setAvailabiltyToAssign(availability)
  }

  const cancelAssignment = () => {
    setRequestToAssign(undefined)
    setAvailabiltyToAssign(undefined)
  }

  return (
    <>
      <Row justify={"space-between"} style={{ marginBottom: 15 }}>
        <Col>
          <Title level={4}>
            <Icon icon={faCalendarDay} fixedWidth /> Demandes du jour
          </Title>
        </Col>
        <Col>
          <Space size={"middle"}>
            <Space>
              <Button icon={<Icon icon={faChevronLeft} />} onClick={() => setDayToView(dayToView.subtract(1, "day"))} />
              <DatePicker onChange={(date) => setDayToView(date || dayjs())} value={dayToView} />
              <Button icon={<Icon icon={faChevronRight} />} onClick={() => setDayToView(dayToView.add(1, "day"))} />
            </Space>

            <Icon icon={faPipe} />

            <RequestFilters />

            <Button className="table-add-data-button" type="primary" icon={<Icon icon={faPlus} />} onClick={() => openNewRequestModal()}>
              {t("requests.button.new_requests")}
            </Button>
          </Space>
        </Col>
      </Row>
      <DndContext onDragEnd={handleDragEnd}>
        <Card
          title="Demandes"
          extra={
            <>
              Afficher les demandes annulées/refusées
              <Switch
                style={{ marginLeft: 7 }}
                checkedChildren={<Icon icon={faCheck} />}
                unCheckedChildren={<Icon icon={faTimes} />}
                onChange={setIsCancelledShown}
                checked={isCancelledShown}
              />
            </>
          }
        >
          <Table
            dataSource={requestsToShow}
            pagination={pagination}
            rowKey="id"
            loading={isRequestsLoading}
            size="small"
            rowClassName="row-clickable"
            onRow={(request: RequestRelations) => ({
              "data-row-disabled": request.status !== RequestStatus.ACCEPTED && request.status !== RequestStatus.WAITING,
              onClick: (e) => {
                if ((e.target as Element).closest(".ant-table-selection-column")) return // Ignore when clicking just near the checkbox
                openRequestDetails(request)
              }
            })}
            onChange={handleTableChange}
            rowSelection={{
              selectedRowKeys: selectedRequests.map((r) => r.id),
              onChange: handleSelectChange
            }}
            components={{ body: { row: RequestRow } }}
          >
            {user && isMobile(user) && <Column key="dnd-handle" width={1} />}
            <Column title="N°" dataIndex="id" />
            <Column title={t("requests.label.service")} dataIndex="careUnitId" render={getServiceName} />
            <Column title={t("requests.label.care_unit")} dataIndex="careUnitId" render={(careUnitId) => <CareUnitTag careUnit={getCareUnit(careUnitId)} />} />
            <Column title={t("requests.label.date")} dataIndex="start" render={(start) => dayjs(start).format("L")} />
            <Column title={t("requests.label.schedule")} render={(request) => `${dayjs(request.start).format("LT")} - ${dayjs(request.end).format("LT")}`} />
            <Column title={t("requests.label.time_off")} dataIndex="timeOff" render={(value) => `${value} ${t("requests.label.time_off.minutes_short")}`} />
            <Column title={t("requests.label.type")} dataIndex="helpTypeValue" render={getLabel} />
            <Column title={t("requests.label.qualification")} dataIndex="qualificationValue" render={getLabel} />
            <Column title={t("requests.label.status")} dataIndex="status" render={(status) => <RequestStatusTag status={status} />} />
            <Column title={t("requests.label.agent")} render={(r) => <AgentInfo request={r} />} />
            {user && (isICS(user) || isMobile(user)) && (
              <Column
                title={
                  <Tooltip title={t("requests.label.is_sent_to_agency.tooltip")}>
                    <Icon icon={faPaperPlane} />
                  </Tooltip>
                }
                dataIndex="isSentToAgency"
                render={(isSent) => <BooleanIcon boolean={isSent} noError />}
              />
            )}
            <Column title={t("requests.label.actions")} render={(request) => <RequestActionsMenu size="small" hideUnusableButtons selectedRequests={[request]} />} />
          </Table>
        </Card>

        {user && isMobile(user) && (
          <Card title="Disponibilités de l'équipe mobile" style={{ marginTop: 25 }}>
            <Table
              dataSource={availabilitiesToShow}
              pagination={pagination}
              rowKey="id"
              loading={isAvailabilitiesLoading}
              size="small"
              rowClassName="row-clickable"
              components={{ body: { row: AvailabilityRow } }}
            >
              <Column title={t("availabilities.label.agent")} dataIndex="agent" render={fullnameAgent} />
              <Column title={t("requests.label.qualification")} dataIndex={["agent", "qualificationValue"]} render={getLabel} />
              <Column title={t("requests.label.date")} dataIndex="start" render={(start) => dayjs(start).format("L")} />
              <Column title={t("requests.label.schedule")} render={(availability) => `${dayjs(availability.start).format("LT")} - ${dayjs(availability.end).format("LT")}`} />
            </Table>

            <AssignAvailabilityModal
              isVisible={!!availabiltyToAssign && !!requestToAssign}
              availability={availabiltyToAssign}
              request={requestToAssign}
              onCancel={cancelAssignment}
            />
          </Card>
        )}

        <div className={classNames("multi-select-toolbar", { hidden: selectedRequests.length < 2 })}>
          {t("requests.multi_select_toolbar.request_selected", { count: selectedRequests.length })}

          <RequestActionsMenu style={{ marginLeft: 15 }} selectedRequests={selectedRequests} />
        </div>
      </DndContext>
    </>
  )
}
