import "./Requests/Requests.less"

import { blue, gold, green, lime, red } from "@ant-design/colors"
import RequestStatusCard from "@components/RequestStatusCard/RequestStatusCard.tsx"
import { RequestsContext } from "@contexts/RequestsContext.tsx"
import { RequestStatus } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService.ts"
import { useQuery } from "@tanstack/react-query"
import { Alert, Col, Row } from "antd"
import { useContext } from "react"
import { useTranslation } from "react-i18next"

const RequestsStatistics = () => {
  const { t } = useTranslation()

  const { setFilters, filters } = useContext(RequestsContext)

  const toggleFilterStatus = (...statuses: RequestStatus[]) => {
    const newFilters = { ...filters }
    for (const status of statuses) {
      if (newFilters.status.includes(status)) {
        newFilters.status = newFilters.status.filter((s) => s !== status)
      } else {
        newFilters.status = [...newFilters.status, status]
      }
    }
    setFilters(newFilters)
  }
  const hasFilterStatus = (status: RequestStatus) => filters.status.includes(status)

  const statistics = useQuery({
    queryKey: ["requests-statistics", { ...filters, status: [] }],
    queryFn: () => api.request.getStatistics({ ...filters, status: [] })
  })

  if (statistics.isError) return <Alert message={t("requests.alert.is_error.message")} description={t("requests.alert.is_error.description")} type="error" showIcon />

  return (
    <>
      <Row gutter={[35, 20]}>
        <Col>
          <RequestStatusCard
            title={t("requests.status.waiting.plural")}
            value={statistics.data?.waiting ?? "-"}
            color={gold}
            onClick={() => toggleFilterStatus(RequestStatus.WAITING)}
            selected={hasFilterStatus(RequestStatus.WAITING)}
            disabled={!hasFilterStatus(RequestStatus.WAITING) && filters.status.length > 0}
          />
        </Col>
        <Col>
          <RequestStatusCard
            title={t("requests.status.accepted.plural")}
            value={statistics.data?.accepted ?? "-"}
            color={blue}
            onClick={() => toggleFilterStatus(RequestStatus.ACCEPTED)}
            selected={hasFilterStatus(RequestStatus.ACCEPTED)}
            disabled={!hasFilterStatus(RequestStatus.ACCEPTED) && filters.status.length > 0}
          />
        </Col>
        <Col>
          <RequestStatusCard
            title={t("requests.status.confirmed.plural")}
            value={statistics.data?.confirmed ?? "-"}
            color={green}
            onClick={() => toggleFilterStatus(RequestStatus.CONFIRMED)}
            selected={hasFilterStatus(RequestStatus.CONFIRMED)}
            disabled={!hasFilterStatus(RequestStatus.CONFIRMED) && filters.status.length > 0}
          />
        </Col>
        <Col>
          <RequestStatusCard
            title={t("requests.status.done.plural")}
            value={statistics.data?.done ?? "-"}
            color={lime}
            onClick={() => toggleFilterStatus(RequestStatus.DONE)}
            selected={hasFilterStatus(RequestStatus.DONE)}
            disabled={!hasFilterStatus(RequestStatus.DONE) && filters.status.length > 0}
          />
        </Col>
        <Col>
          <RequestStatusCard
            title={t("requests.status.canceled_and_rejected.plural")}
            value={statistics.data?.cancelled ?? "-"}
            color={red}
            onClick={() => {
              toggleFilterStatus(RequestStatus.CANCELLED, RequestStatus.REJECTED)
            }}
            selected={hasFilterStatus(RequestStatus.CANCELLED)}
            disabled={!hasFilterStatus(RequestStatus.CANCELLED) && filters.status.length > 0}
          />
        </Col>
      </Row>
    </>
  )
}

export default RequestsStatistics
