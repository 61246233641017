import "./index.less"
import "dayjs/locale/fr"

import Root from "@components/Root"
import { AuthProvider } from "@contexts/AuthContext"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ConfigProvider, message } from "antd"
import frBE from "antd/es/locale/fr_BE"
import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import localizedFormat from "dayjs/plugin/localizedFormat"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpBackend from "i18next-http-backend"
import ReactDOM from "react-dom/client"
import { initReactI18next } from "react-i18next"
import { BrowserRouter } from "react-router-dom"

// Dayjs config
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale("fr")
dayjs.tz.setDefault("Europe/Brussels")

// i18n config
i18next.use(HttpBackend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: "fr",
  debug: true,
  load: "languageOnly"
})

// UseQuery client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    },
    mutations: {
      onError: async (error) => {
        const response = (error as { response: Response }).response
        const body = await response.json()
        response.json = body
        if (body.code) {
          message.warning(i18next.t(`errors.${body.code}`))
        } else {
          message.error(i18next.t("errors.an_unknown_error_has_occurred"))
        }
      }
    }
  }
})

// Root creation
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ConfigProvider
    locale={frBE}
    iconPrefixCls="svg-inline--fa"
    theme={{
      token: {
        colorPrimary: "#0f4c9a",
        colorPrimaryBg: "rgba(13,76,153,0.1)",
        fontFamily: 'Nunito, "MS Sans Serif", Geneva, sans-serif'
      }
    }}
  >
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <Root />
        </AuthProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ConfigProvider>
)
