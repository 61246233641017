import { Icon } from "@components/Icon.tsx"
import AuthContext from "@contexts/AuthContext.tsx"
import { RequestsContext } from "@contexts/RequestsContext.tsx"
import SelectsContext from "@contexts/SelectsContext.tsx"
import { faBarsFilter, faCheckDouble, faFilterSlash } from "@fortawesome/pro-light-svg-icons"
import { isIC, isICS } from "@utils/utils.ts"
import { Button, DatePicker, Dropdown, Radio, Select, Space, theme } from "antd"
import dayjs from "dayjs"
import { useContext } from "react"
import { useTranslation } from "react-i18next"

const { useToken } = theme

export type RequestFiltersType = {
  useDate?: boolean
}

const RequestFilters = (props: RequestFiltersType) => {
  const { t } = useTranslation()
  const { token } = useToken()
  const { user } = useContext(AuthContext)
  const { careUnits, services } = useContext(SelectsContext)
  const { filters, setFilters, resetFilters } = useContext(RequestsContext)

  const { useDate } = props

  return (
    <Space>
      <Dropdown
        overlayClassName="filters-dropdown"
        destroyPopupOnHide
        trigger={["click"]}
        dropdownRender={() => (
          <div
            style={{
              width: 350,
              backgroundColor: token.colorBgElevated,
              borderRadius: token.borderRadiusLG,
              boxShadow: token.boxShadowSecondary,
              padding: token.padding
            }}
          >
            {user &&
              (isICS(user) ? (
                <p>{t("requests.filter.visible_info_ics", { count: user.services?.length })}</p>
              ) : (
                isIC(user) && <p>{t("requests.filter.visible_info_ic", { count: user.careUnits?.length })}</p>
              ))}

            <label>{t("requests.filter.services")}</label>
            <Space.Compact block style={{ marginBottom: 15 }}>
              <Select
                value={filters.services}
                onChange={(selected) => setFilters({ ...filters, services: selected, careUnits: [] })}
                style={{ width: "100%" }}
                mode="multiple"
                allowClear
                options={services?.map((s) => ({ value: s.id, label: s.name }))}
                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
              />
              <Button icon={<Icon icon={faCheckDouble} />} onClick={() => setFilters({ ...filters, services: services?.map((s) => s.id) || [], careUnits: [] })}>
                {t("misc.all")}
              </Button>
            </Space.Compact>

            <label>{t("requests.filter.care_units")}</label>
            <Select
              value={filters.careUnits}
              onChange={(selected) => setFilters({ ...filters, services: [], careUnits: selected })}
              style={{ width: "100%", marginBottom: 15 }}
              mode="multiple"
              allowClear
              options={careUnits?.map((c) => ({ value: c.id, label: `${c.code} ${c.name}` }))}
              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            />

            {useDate && (
              <>
                <label>{t("requests.filter.dates")}</label>
                <DatePicker.RangePicker
                  changeOnBlur
                  value={[filters.start ? dayjs(filters.start) : null, filters.end ? dayjs(filters.end) : null]}
                  onChange={(dates) => setFilters({ ...filters, start: dates?.[0]?.toISOString(), end: dates?.[1]?.toISOString() })}
                />
              </>
            )}

            <div className="filter-radio-send">
              <label style={{ display: "block" }}>{t("requests.label.send_interim")}</label>

              <Radio.Group
                defaultValue={filters.sendInterim}
                buttonStyle="solid"
                style={{ paddingTop: 0 }}
                onChange={(e) => setFilters({ ...filters, sendInterim: e.target.value })}
              >
                <Radio.Button value="yes">oui</Radio.Button>
                <Radio.Button value="no">non</Radio.Button>
                <Radio.Button value="all">tous</Radio.Button>
              </Radio.Group>
            </div>
          </div>
        )}
      >
        <Button icon={<Icon icon={faBarsFilter} fixedWidth />}>{t("misc.filter")}</Button>
      </Dropdown>
      {filters.status.length > 0 || filters.services.length || filters.careUnits.length || filters.start || filters.end || filters.sendInterim !== "all" ? (
        <Button icon={<Icon icon={faFilterSlash} fixedWidth />} onClick={resetFilters}>
          {t("requests.reset_filters")}
        </Button>
      ) : null}
    </Space>
  )
}

export default RequestFilters
