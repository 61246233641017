import { presetPrimaryColors } from "@ant-design/colors"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faCheck, faInfoCircle, faMinus, faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { Popover, Tooltip } from "antd"
import { CSSProperties } from "react"

/**
 * Overrides the regular Fontawesome Icon component, by adding :
 *  - Allows giving only the icon string name into the icon prop (take the light version)
 *  - Allows to give an ant-design color into the color prop
 */
interface IconProps extends FontAwesomeIconProps {
  icon: IconDefinition
}
export const Icon = (props: IconProps) => {
  const { ...newProps } = props
  if (props.color) {
    if (presetPrimaryColors[props.color]) newProps.color = presetPrimaryColors[props.color]
    newProps.style = props.style || {}
    newProps.style.color = newProps.color
  }
  return <FontAwesomeIcon {...newProps} />
}

/**
 * Convenient icon to show a boolean (check/cross)
 */
type BooleanIconProps = {
  boolean: boolean
  /** Whether there must be a red cross or only a grey line */
  noError?: boolean
}
export const BooleanIcon = ({ boolean, noError = false }: BooleanIconProps) => {
  switch (boolean) {
    case true:
      return <Icon icon={faCheck} style={{ color: "#52c41a" }} fixedWidth size="lg" />
    case false:
      return noError ? <Icon icon={faMinus} fixedWidth size="lg" color="#C0C0C0" /> : <Icon icon={faTimes} style={{ color: "#f5222d" }} fixedWidth size="lg" />
    case null:
    case undefined:
    default:
      return <Icon icon={faMinus} fixedWidth size="lg" color="#C0C0C0" />
  }
}

/**
 * Info icon with a tooltip or a popover.
 * A popover must contains an object with title and content (String or JSX)
 */

type InfoIconProps = {
  tooltip?: string | JSX.Element
  popover?: { title: string | JSX.Element; content: string | JSX.Element }
  style?: CSSProperties
}
export const InfoIcon = ({ tooltip, popover, style = {} }: InfoIconProps) => {
  const icon = <Icon icon={faInfoCircle} style={style} />
  if (tooltip) return <Tooltip title={tooltip}>{icon}</Tooltip>
  if (popover)
    return (
      <Popover title={popover.title} content={popover.content}>
        {icon}
      </Popover>
    )
  return icon
}
