import AgentsList from "@components/Agent/AgentsList.tsx"
import { Icon } from "@components/Icon.tsx"
import { AgentsContext } from "@contexts/AgentsContext.tsx"
import { faCheck, faPlus, faTimes, faUsersMedical } from "@fortawesome/pro-light-svg-icons"
import { AgentType } from "@opal/interimeo-openapi"
import { Button, Card, Col, Form, Input, Row, Switch, Typography } from "antd"
import { debounce } from "lodash"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"

const { Title } = Typography

const AgentsMobile = () => {
  const { t } = useTranslation()
  const { filters, setFilters, openNewAgentModal } = useContext(AgentsContext)
  const [form] = Form.useForm()

  useEffect(() => {
    setFilters({ ...filters, type: AgentType.MOBILE })
  }, [filters, setFilters])

  const handleSearch = debounce((search: string) => setFilters({ ...filters, search }), 500)

  return (
    <>
      <Row justify={"space-between"} style={{ marginBottom: 15 }}>
        <Col>
          <Title level={4}>
            <Icon icon={faUsersMedical} fixedWidth /> {t("app.sider.mobile_team")}
          </Title>
        </Col>
        <Col>
          <Button className="table-add-data-button" type="primary" icon={<Icon icon={faPlus} />} onClick={() => openNewAgentModal()}>
            {t("requests.button.new_agent")}
          </Button>
        </Col>
      </Row>

      <Card>
        <Form form={form}>
          <Row gutter={[35, 35]}>
            <Col>
              <Form.Item name="search">
                <Input placeholder={t("agent.placeholder.search")} value={filters.search} onChange={(e) => handleSearch(e.target.value)} />
              </Form.Item>
            </Col>
            <Col flex="auto"></Col>
            <Col>
              {t("agent.filter.show_disabled_agents")}
              <Switch
                style={{ marginLeft: 10 }}
                checkedChildren={<Icon icon={faCheck} />}
                unCheckedChildren={<Icon icon={faTimes} />}
                checked={filters.disabled}
                onChange={(disabled) => setFilters({ ...filters, disabled })}
              />
            </Col>
          </Row>
        </Form>

        {filters.type === AgentType.MOBILE && <AgentsList />}
      </Card>
    </>
  )
}

export default AgentsMobile
