import "./AssignAgentToRequestModal.less"

import { Icon } from "@components/Icon"
import AuthContext from "@contexts/AuthContext.tsx"
import SelectsContext from "@contexts/SelectsContext"
import { faCalendarCirclePlus, faCheck, faSave, faTimes, faUserPlus } from "@fortawesome/pro-light-svg-icons"
import { CustomShiftsType } from "@modules/CustomShiftsType"
import { AgentRelations, AgentType, Availability, AvailabilityCreateAgent, AvailabilityRelations, GetAllAgencies200ResponseInner, Request } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { showMessageError } from "@utils/errorUtils.ts"
import { handleChangeShifts } from "@utils/requestFormUtils.ts"
import { fullnameAgent, getDateTimeBySelectOption, isICS, isMobile } from "@utils/utils.ts"
import { Alert, DatePicker, Form, Input, message, Modal, Radio, Select, Switch, Table, Tabs, TabsProps, TimePicker, Tooltip } from "antd"
import dayjs from "dayjs"
import { omit } from "lodash"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const { Column } = Table

type AssignAgentToRequestModalProps = {
  open: boolean
  request?: Request
  onOK: () => void
  onCancel: () => void
}

type InterimFormManageType = {
  agencyId: number | string
  agentExist: string
  agency: GetAllAgencies200ResponseInner
  showAlertCancelOverlap: boolean
  cancelOverlap: boolean
}

type MobileFormManageType = {
  agentExist: string
  agents: AgentRelations[] | undefined
  showAlertCancelOverlap: boolean
  cancelOverlap: boolean
}

export const AssignAgentToRequestModal = ({ open, onOK, request, onCancel }: AssignAgentToRequestModalProps) => {
  const { t } = useTranslation()

  const [useCustomSchedule, setUseCustomSchedule] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const [activeTab, setActiveTab] = useState("MOBILE")
  const [selectedAvailability, setSelectedAvailability] = useState<Availability | undefined>()

  const { getSelectOption, getShiftByRequest, getLabel, agencies, qualifications, shifts, getAgencyById, getAgentsByType } = useContext(SelectsContext)
  const { user } = useContext(AuthContext)

  const [formInterim] = Form.useForm()
  const [formMobile] = Form.useForm()

  const [interimFormManageValuesDefault] = useState<InterimFormManageType>({ agencyId: "", agentExist: "yes", agency: {}, showAlertCancelOverlap: false, cancelOverlap: false })
  const [interimFormManage, setInterimFormManage] = useState<InterimFormManageType>({ ...interimFormManageValuesDefault })

  const [mobileFormManageValuesDefault] = useState<MobileFormManageType>({ agentExist: "yes", agents: [], showAlertCancelOverlap: false, cancelOverlap: false })
  const [mobileFormManage, setMobileFormManage] = useState<MobileFormManageType>({ ...mobileFormManageValuesDefault })

  useEffect(() => {
    if (open) {
      formInterim.resetFields()
      formMobile.resetFields()
    }
    if (open && request) {
      const shiftValueByTimesType = getShiftByRequest(request)
      setUseCustomSchedule(shiftValueByTimesType.isCustom)

      const fieldValues = {
        ...request,
        date: dayjs(request.start),
        shiftValue: shiftValueByTimesType.shiftValue,
        timeStart: dayjs(request.start),
        timeEnd: dayjs(request.end)
      }

      formInterim.setFieldsValue({ ...fieldValues, ...interimFormManageValuesDefault })
      setInterimFormManage({ ...interimFormManageValuesDefault })

      formMobile.setFieldsValue({ ...fieldValues, ...mobileFormManageValuesDefault })
      setMobileFormManage({ ...mobileFormManageValuesDefault, agents: getAgentsByType(AgentType.MOBILE) })
    }
  }, [open, formInterim, formMobile, request, getShiftByRequest, interimFormManageValuesDefault, getAgentsByType, mobileFormManageValuesDefault])

  useEffect(() => {
    if (typeof interimFormManage.agencyId === "number" && interimFormManage.agencyId !== interimFormManage.agency.id) {
      setInterimFormManage({ ...interimFormManage, agency: getAgencyById(interimFormManage.agencyId) ?? {} })
    }
  }, [getAgencyById, interimFormManage])

  const availabilitiesQuery = useQuery({
    queryKey: ["availabilities", { start: request?.start, end: request?.end }, { user }],
    queryFn: () => {
      if (user && isICS(user) && isMobile(user)) {
        return api.availability.getAllAvailabilities({
          start: dayjs(request?.start)
            .subtract(3, "hours")
            .toDate(),
          end: dayjs(request?.end)
            .add(3, "hours")
            .toDate(),
          type: "MOBILE"
        })
      }

      return Promise.resolve({ data: [], isLoading: false }) as unknown as Promise<AvailabilityRelations[]>
    },
    enabled: !!request
  })

  const availabilities = availabilitiesQuery.data && Array.isArray(availabilitiesQuery.data) ? availabilitiesQuery.data?.filter((a) => !a.canceledAt && !a.requestId) : []

  const save = () => {
    if (activeTab === "INTERIM") {
      formInterim.submit()
    } else if (activeTab === "MOBILE_NEW") {
      formMobile.submit()
    } else {
      api.request
        .confirmOneRequest(request!.id, selectedAvailability!.id, {})
        .then(() => {
          queryClient.invalidateQueries(["requests"])
          queryClient.invalidateQueries(["requestsStatisticsSider"])
          queryClient.invalidateQueries(["requests-cancelled-assignments"])
          setIsLoading(false)
          onOK()
          message.success("Agent affecté à la demande.")
        })
        .catch(() => {
          setIsLoading(false)
          message.error(t("errors.an_unknown_error_has_occurred"))
        })
    }
  }
  // TODO fix
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const saveInterimOrMobile = (type: "INTERIM" | "MOBILE") => (formValues) => {
    const valuesClone = { ...formValues }
    const date = dayjs(formValues.date)
    const selectOption = getSelectOption(formValues.shiftValue)
    const times: CustomShiftsType = {
      start: valuesClone.timeStart,
      end: valuesClone.timeEnd
    }

    omit(valuesClone, ["date", "timeStart", "timeEnd"])

    const availability: AvailabilityCreateAgent = {
      ...valuesClone,
      start: getDateTimeBySelectOption(date, selectOption, times, "start"),
      end: getDateTimeBySelectOption(date, selectOption, times, "end"),
      cancelOverlap: type === "MOBILE" ? mobileFormManage.cancelOverlap : interimFormManage.cancelOverlap,
      type
    }

    setIsLoading(true)

    api.request
      .insertAvailabilityByAgentAndConfirmOneRequest(request!.id, { availabilityCreateAgent: availability })
      .then(() => {
        queryClient.invalidateQueries(["requests"])
        queryClient.invalidateQueries(["requestsStatisticsSider"])
        queryClient.invalidateQueries(["requests-cancelled-assignments"])
        setIsLoading(false)
        onOK()
        message.success("Agent affecté à la demande.")
      })
      .catch((error) =>
        showMessageError(error, t, (json) => {
          if (json.code === "confirm_to_cancel_overlap_availability") {
            if (type === "INTERIM") setInterimFormManage({ ...interimFormManage, showAlertCancelOverlap: true, cancelOverlap: false })
            if (type === "MOBILE") setMobileFormManage({ ...mobileFormManage, showAlertCancelOverlap: true, cancelOverlap: false })
          }

          setIsLoading(false)
        })
      )
  }

  const items: TabsProps["items"] = [
    {
      key: "MOBILE",
      label: "Equipe Mobile",
      children: (
        <>
          {availabilities?.length ? (
            <>
              <Alert
                type="info"
                showIcon
                message="Choisissez ici une plage horaire existante pour un agent de l'équipe mobile. S'il n'y a pas de plage disponible, vous pouvez en créer une dans l'onglet suivant."
                style={{ marginBottom: 25 }}
              />
              <Table
                dataSource={availabilities}
                loading={availabilitiesQuery.isLoading}
                size="small"
                rowSelection={{ type: "radio", onChange: (_selectedRowKeys, selectedRows: Availability[]) => setSelectedAvailability(selectedRows[0]) }}
                rowKey="id"
              >
                <Column title="Date" render={(a) => `${dayjs(a.start).format("L")}`} />
                <Column title="Horaire" render={(a) => `${dayjs(a.start).format("LT")} - ${dayjs(a.end).format("LT")}`} />
                <Column title="Nom" render={(a) => fullnameAgent(a.agent)} />
                <Column title="Qualification" render={(a) => getLabel(a.agent.qualificationValue)} />
              </Table>
            </>
          ) : (
            <Alert
              type="info"
              showIcon
              message="Il n'y a aucun agent de l'équipe mobile disponible pour cette date. Vous pouvez éventuellement assigner manuellement un agent dans l'onglet suivant."
              style={{ marginBottom: 25 }}
            />
          )}
        </>
      )
    },
    {
      key: "MOBILE_NEW",
      label: "Equipe Mobile (manuel)",
      children: (
        <>
          <Alert
            type="info"
            showIcon
            message="Associez ici un agent de l'équipe mobile, existant ou non. Ceci ajoutera également une nouvelle plage horaire dans le calendrier de l'agent."
          />
          <></>
          <Form
            form={formMobile}
            layout="horizontal"
            onFinish={saveInterimOrMobile("MOBILE")}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{ marginTop: 30 }}
            requiredMark={false}
          >
            <div className="code-box" style={{ marginBottom: "24px", padding: "0 19px 0 7px" }}>
              <Form.Item name="agentExist" label="Agent existant" rules={[{ required: true }]}>
                <Radio.Group buttonStyle="solid" onChange={(e) => setMobileFormManage({ ...mobileFormManage, agentExist: e.target.value })}>
                  <Radio.Button value="yes">oui</Radio.Button>
                  <Radio.Button value="no">non</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {mobileFormManage.agentExist === "no" && (
                <>
                  <Form.Item name="firstname" label="Prénom" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="lastname" label="Nom" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="qualificationValue" label={t("requests.label.qualification")} rules={[{ required: true }]} style={{ marginBottom: 0 }}>
                    <Radio.Group buttonStyle="solid">
                      {qualifications?.map((s) => (
                        <Radio.Button key={s.value} value={s.value}>
                          {s.label}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </>
              )}

              {mobileFormManage.agentExist === "yes" && (
                <Form.Item name="agentId" label="Agent" rules={[{ required: true }]} style={{ marginBottom: 0 }}>
                  <Select>
                    {mobileFormManage.agents?.map((a) => (
                      <Select.Option key={a.id} value={a.id}>
                        {a.firstname} {a.lastname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
            <Form.Item name="date" label={t("requests.label.date")} rules={[{ required: true }]}>
              <DatePicker style={{ width: "100%" }} format="L" changeOnBlur />
            </Form.Item>
            <Form.Item name="shiftValue" label={t("requests.label.schedule")} rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid" onChange={(event) => handleChangeShifts(event, setUseCustomSchedule)}>
                {shifts?.map((s) => (
                  <Radio.Button key={s.value} value={s.value}>
                    {s.label}
                  </Radio.Button>
                ))}

                <Tooltip title={t("requests.button.custom_schedule")}>
                  <Radio.Button key="custom_schedule" value="custom_schedule">
                    <Icon icon={faCalendarCirclePlus} />
                  </Radio.Button>
                </Tooltip>
              </Radio.Group>
            </Form.Item>
            <Form.Item label={t("requests.label.custom_schedule")} hidden={!useCustomSchedule}>
              <Form.Item
                name="timeStart"
                rules={[{ required: useCustomSchedule, message: t("requests.required.message.the_field_is_required", { field: t("requests.placeholder.start_schedule") }) }]}
                noStyle
              >
                <TimePicker
                  showSecond={false}
                  format={"HH:mm"}
                  placeholder={t("requests.placeholder.start_schedule")}
                  minuteStep={5}
                  disabled={!useCustomSchedule}
                  style={{ width: "38%" }}
                  changeOnBlur
                />
              </Form.Item>
              &nbsp;-&nbsp;
              <Form.Item
                name="timeEnd"
                rules={[{ required: useCustomSchedule, message: t("requests.required.message.the_field_is_required", { field: t("requests.placeholder.end_schedule") }) }]}
                noStyle
              >
                <TimePicker
                  showSecond={false}
                  format={"HH:mm"}
                  placeholder={t("requests.placeholder.end_schedule")}
                  minuteStep={5}
                  disabled={!useCustomSchedule}
                  style={{ width: "38%" }}
                  changeOnBlur
                />
              </Form.Item>
            </Form.Item>
            <Form.Item name="comment" label={t("requests.label.comment")}>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>

            {mobileFormManage.showAlertCancelOverlap && (
              <Alert
                key="cancelOverlapMobile"
                type="warning"
                message={t("requests.modal.confirm_for_cancel_overlap.title")}
                description={
                  <>
                    {t("requests.modal.confirm_for_cancel_overlap.message")}
                    <Switch
                      style={{ marginLeft: 7 }}
                      checkedChildren={<Icon icon={faCheck} />}
                      unCheckedChildren={<Icon icon={faTimes} />}
                      onChange={(cancelOverlap) => setMobileFormManage({ ...mobileFormManage, cancelOverlap })}
                      checked={mobileFormManage.cancelOverlap}
                    />
                  </>
                }
                style={{ marginBottom: 15 }}
                showIcon
              />
            )}
          </Form>
        </>
      )
    },
    {
      key: "INTERIM",
      label: "Intérimaire",
      children: (
        <Form
          form={formInterim}
          layout="horizontal"
          onFinish={saveInterimOrMobile("INTERIM")}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          style={{ marginTop: 30 }}
          requiredMark={false}
        >
          <Form.Item name="agencyId" label="Agence" rules={[{ required: true }]}>
            <Select onChange={(value) => setInterimFormManage({ ...interimFormManage, agencyId: value })}>
              {agencies?.map((a) => (
                <Select.Option key={a.id} value={a.id}>
                  {a.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {interimFormManage.agencyId !== "" && (
            <div className="code-box" style={{ marginBottom: "24px", padding: "0 19px 0 7px" }}>
              <Form.Item name="agentExist" label="Agent existant" rules={[{ required: true }]}>
                <Radio.Group buttonStyle="solid" onChange={(e) => setInterimFormManage({ ...interimFormManage, agentExist: e.target.value })}>
                  <Radio.Button value="yes">oui</Radio.Button>
                  <Radio.Button value="no">non</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {interimFormManage.agentExist === "no" && (
                <>
                  <Form.Item name="firstname" label="Prénom" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="lastname" label="Nom" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="qualificationValue" label={t("requests.label.qualification")} rules={[{ required: true }]} style={{ marginBottom: 0 }}>
                    <Radio.Group buttonStyle="solid">
                      {qualifications?.map((s) => (
                        <Radio.Button key={s.value} value={s.value}>
                          {s.label}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </>
              )}

              {interimFormManage.agentExist === "yes" && (
                <Form.Item name="agentId" label="Agent" rules={[{ required: true }]} style={{ marginBottom: 0 }}>
                  <Select>
                    {interimFormManage.agency.agents?.map((a) => (
                      <Select.Option key={a.id} value={a.id}>
                        {a.firstname} {a.lastname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
          )}

          <Form.Item name="date" label={t("requests.label.date")} rules={[{ required: true }]}>
            <DatePicker style={{ width: "100%" }} format="L" changeOnBlur />
          </Form.Item>

          <Form.Item name="shiftValue" label={t("requests.label.schedule")} rules={[{ required: true }]}>
            <Radio.Group buttonStyle="solid" onChange={(event) => handleChangeShifts(event, setUseCustomSchedule)}>
              {shifts?.map((s) => (
                <Radio.Button key={s.value} value={s.value}>
                  {s.label}
                </Radio.Button>
              ))}

              <Tooltip title={t("requests.button.custom_schedule")}>
                <Radio.Button key="custom_schedule" value="custom_schedule">
                  <Icon icon={faCalendarCirclePlus} />
                </Radio.Button>
              </Tooltip>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={t("requests.label.custom_schedule")} hidden={!useCustomSchedule}>
            <Form.Item
              name="timeStart"
              rules={[{ required: useCustomSchedule, message: t("requests.required.message.the_field_is_required", { field: t("requests.placeholder.start_schedule") }) }]}
              noStyle
            >
              <TimePicker
                showSecond={false}
                format={"HH:mm"}
                placeholder={t("requests.placeholder.start_schedule")}
                minuteStep={5}
                disabled={!useCustomSchedule}
                style={{ width: "38%" }}
                changeOnBlur
              />
            </Form.Item>
            &nbsp;-&nbsp;
            <Form.Item
              name="timeEnd"
              rules={[{ required: useCustomSchedule, message: t("requests.required.message.the_field_is_required", { field: t("requests.placeholder.end_schedule") }) }]}
              noStyle
            >
              <TimePicker
                showSecond={false}
                format={"HH:mm"}
                placeholder={t("requests.placeholder.end_schedule")}
                minuteStep={5}
                disabled={!useCustomSchedule}
                style={{ width: "38%" }}
                changeOnBlur
              />
            </Form.Item>
          </Form.Item>
          <Form.Item name="comment" label={t("requests.label.comment")}>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>

          {interimFormManage.showAlertCancelOverlap && (
            <Alert
              key="cancelOverlapInterim"
              type="warning"
              message={t("requests.modal.confirm_for_cancel_overlap.title")}
              description={
                <>
                  {t("requests.modal.confirm_for_cancel_overlap.message")}
                  <Switch
                    style={{ marginLeft: 7 }}
                    checkedChildren={<Icon icon={faCheck} />}
                    unCheckedChildren={<Icon icon={faTimes} />}
                    onChange={(cancelOverlap) => setInterimFormManage({ ...interimFormManage, cancelOverlap })}
                    checked={interimFormManage.cancelOverlap}
                  />
                </>
              }
              style={{ marginBottom: 15 }}
              showIcon
            />
          )}
        </Form>
      )
    }
  ]

  return (
    <Modal
      title={
        <>
          <Icon icon={faUserPlus} fixedWidth /> {t("requests.modal.assign_agent.title")}
        </>
      }
      className="assign-agent-modal"
      open={open}
      maskClosable={false}
      onCancel={onCancel}
      onOk={save}
      okText={t("requests.modal.assign_agent.save_button")}
      okButtonProps={{ icon: <Icon icon={faSave} />, loading: isLoading, disabled: activeTab === "MOBILE" && !selectedAvailability }}
      width={600}
      destroyOnClose={true}
    >
      <Tabs defaultActiveKey="1" items={items} centered activeKey={activeTab} onChange={setActiveTab} />
    </Modal>
  )
}
