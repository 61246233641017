import { presetPalettes } from "@ant-design/colors"
import { CustomShiftsType } from "@modules/CustomShiftsType"
import { MetadataShiftType } from "@modules/selectOption"
import { Agent, ApiResponse, RequestStatus, SelectOption, User, UserRoles, UserShort } from "@opal/interimeo-openapi"
import dayjs, { Dayjs } from "dayjs"

// TODO put this into a model class ?
export const fullname = (user: UserShort) => `${user.firstname} ${user.lastname}`
export const fullnameAgent = (agent?: Agent) => (agent ? `${agent.firstname} ${agent.lastname}` : "-")

export const fullnameOrCaret = (user: UserShort | undefined) => (user ? fullname(user) : "-")

export const isMobile = (user: User) => !!user.roles.find((r) => r === UserRoles.MOBILE)
export const isICS = (user: User) => !!user.roles.find((r) => r === UserRoles.ICS)
export const isIC = (user: User) => !!user.roles.find((r) => r === UserRoles.IC)

const getAntdColor = (color: string, light = false) => (light ? presetPalettes[color][0] : presetPalettes[color][5])
export const getRequestStatusColor = (status: RequestStatus, light = false) => {
  switch (status) {
    case RequestStatus.WAITING:
      return getAntdColor("gold", light)
    case RequestStatus.ACCEPTED:
      return getAntdColor("blue", light)
    case RequestStatus.REJECTED:
      return getAntdColor("volcano", light)
    case RequestStatus.CONFIRMED:
      return getAntdColor("green", light)
    case RequestStatus.DONE:
      return getAntdColor("blue", light)
    case RequestStatus.CANCELLED:
      return getAntdColor("red", light)
    default:
      return "#000"
  }
}

/**
 * Download a file from a specific query function (api fetch method).
 * The API fetch method must be the RAW version, to access the response.
 * The response can have a content-disposition header with the filename.
 * @param queryFn the API fetch method (raw version !)
 */
export const downloadFile = async (queryFn: () => Promise<ApiResponse<unknown>>) => {
  const response = await queryFn()
  const fileContents: Blob = await response.raw.blob()
  const match = response.raw.headers.get("content-disposition")?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
  const filename = match ? match[1] : "file"
  const a = document.createElement("a")
  a.href = window.URL.createObjectURL(fileContents)
  a.download = filename
  document.body.appendChild(a)
  a.click()
  a.remove()
}

/**
 * The choice of shifts is made in selectOption (shifts saved in the database) or times (the form with the
 * choice of hours), which represents the start and end date. In the method, one or the other is selected.
 * Subsequently, the selected date is injected.
 *
 * @param date            Dayjs                         Date selected
 * @param selectOption    SelectOption | undefined      Shift.metadata { start: toISOString, end: toISOString }
 * @param times           CustomShiftsType              { start: toISOString, end: toISOString }
 * @param type            string                        "start" or "end"
 */
export const getDateTimeBySelectOption = (date: Dayjs, selectOption: SelectOption | undefined, times: CustomShiftsType, type: "start" | "end"): Date => {
  let d = dayjs()

  if (selectOption !== undefined) {
    const metadata = selectOption.metadata as MetadataShiftType

    if (metadata[type] !== undefined) {
      d = dayjs(metadata[type])
    }

    if (type === "end" && d.format("HH:mm:ss") < dayjs(metadata["start"]).format("HH:mm:ss")) {
      date = date.add(1, "day")
    }
  } else {
    d = dayjs(times[type])

    if (type === "end" && d.format("HH:mm:ss") < dayjs(times["start"]).format("HH:mm:ss")) {
      date = date.add(1, "day")
    }
  }

  d = d.set("y", date.get("y")).set("M", date.get("M")).set("D", date.get("D"))

  return d.toDate()
}

export const getTimeByShift = ({ start, end }: MetadataShiftType): string => {
  const s = `${dayjs(start).format("H")}h${dayjs(start).format("mm")}`
  const e = `${dayjs(end).format("H")}h${dayjs(end).format("mm")}`

  return `${s} - ${e}`
}
