import "./RequestsList.less"

import { BooleanIcon, Icon } from "@components/Icon.tsx"
import RequestActionsMenu from "@components/RequestActionsMenu.tsx"
import RequestComments from "@components/RequestsList/RequestComments.tsx"
import { CareUnitTag, RequestStatusTag } from "@components/Tags.tsx"
import AuthContext from "@contexts/AuthContext.tsx"
import { RequestsContext } from "@contexts/RequestsContext.tsx"
import SelectsContext from "@contexts/SelectsContext.tsx"
import { faCommentLines, faFileExcel, faPaperPlane } from "@fortawesome/pro-light-svg-icons"
import { RequestRelations } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService.ts"
import { useQuery } from "@tanstack/react-query"
import { downloadFile, fullnameAgent, isICS, isMobile } from "@utils/utils.ts"
import { Button, Table, Tooltip } from "antd"
import { TablePaginationConfig } from "antd/es/table"
import classNames from "classnames"
import dayjs from "dayjs"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useMap } from "react-use"

const { Column } = Table

export const AgentInfo = ({ request }: { request: RequestRelations }) => {
  const { getLabel } = useContext(SelectsContext)
  const affectation = request?.availabilities.filter((a) => !a.canceledAt)[0]
  return affectation ? `${fullnameAgent(affectation?.agent)}${affectation.agent?.qualificationValue ? " - " + getLabel(affectation.agent?.qualificationValue) : ""}` : "-"
}

const RequestsList = () => {
  const [pagination, { set: setPagination }] = useMap<TablePaginationConfig>({
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: true
  })

  const { getLabel, getCareUnit, getServiceName } = useContext(SelectsContext)
  const { user } = useContext(AuthContext)
  const { filters, openRequestDetails, selectedRequests, setSelectedRequests } = useContext(RequestsContext)

  const { t } = useTranslation()

  // Reset current pagination to 1 when filters change
  useEffect(() => {
    setPagination("current", 1)
  }, [filters, setPagination])

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["requests", { pagination: { current: pagination.current, pagesize: pagination.pageSize }, filters }],
    queryFn: () => api.request.getAllRequests(pagination.current, pagination.pageSize, filters)
  })

  useEffect(() => {
    if (user) {
      refetch().finally()
    }
  }, [user, refetch])

  // Set pagination total items when data changes
  useEffect(() => {
    if (pagination.total !== data?.totalItems && data?.totalItems !== undefined) {
      setPagination("total", data.totalItems)
    }
  }, [data?.totalItems, pagination, setPagination, setSelectedRequests])

  // Loose selection when filters or pagination change
  useEffect(() => setSelectedRequests([]), [filters, pagination, setSelectedRequests])

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    if (pagination.current !== newPagination.current) setPagination("current", newPagination.current)
    if (pagination.pageSize !== newPagination.pageSize) setPagination("pageSize", newPagination.pageSize)
  }

  const handleSelectChange = (_selectedRowKeys: React.Key[], selectedRows: RequestRelations[]) => {
    setSelectedRequests(selectedRows)
  }

  const handleDownloadExcel = async () => {
    await downloadFile(() => api.request.getAllRequestsRaw({ filter: filters }, async (params) => ({ headers: { ...params.init.headers, accept: "application/vnd.ms-excel" } })))
  }

  return (
    <>
      <Table
        dataSource={data?.items}
        pagination={pagination}
        rowKey="id"
        loading={isLoading}
        size="small"
        style={{ marginTop: 30 }}
        rowClassName="row-clickable"
        onRow={(request: RequestRelations) => ({
          onClick: (e) => {
            if ((e.target as Element).closest(".ant-table-selection-column")) return // Ignore when clicking just near the checkbox
            openRequestDetails(request)
          }
        })}
        onChange={handleTableChange}
        rowSelection={{
          selectedRowKeys: selectedRequests.map((r) => r.id),
          onChange: handleSelectChange
        }}
      >
        <Column title="N°" dataIndex="id" />
        <Column title={t("requests.label.service")} dataIndex="careUnitId" render={getServiceName} />
        <Column title={t("requests.label.care_unit")} dataIndex="careUnitId" render={(careUnitId) => <CareUnitTag careUnit={getCareUnit(careUnitId)} />} />
        <Column title={t("requests.label.date")} dataIndex="start" render={(start) => dayjs(start).format("L")} />
        <Column title={t("requests.label.schedule")} render={(request) => `${dayjs(request.start).format("LT")} - ${dayjs(request.end).format("LT")}`} />
        <Column title={t("requests.label.time_off")} dataIndex="timeOff" render={(value) => `${value} ${t("requests.label.time_off.minutes_short")}`} />
        <Column title={t("requests.label.type")} dataIndex="helpTypeValue" render={getLabel} />
        <Column title={t("requests.label.qualification")} dataIndex="qualificationValue" render={getLabel} />
        <Column title={t("requests.label.agent")} render={(r) => <AgentInfo request={r} />} />
        <Column title={t("requests.label.status")} dataIndex="status" render={(status) => <RequestStatusTag status={status} />} />
        <Column
          title={
            <Tooltip title={t("requests.label.comments.tooltip")}>
              <Icon icon={faCommentLines} />
            </Tooltip>
          }
          render={(r) => <RequestComments request={r} />}
        />
        {user && (isICS(user) || isMobile(user)) && (
          <Column
            title={
              <Tooltip title={t("requests.label.is_sent_to_agency.tooltip")}>
                <Icon icon={faPaperPlane} />
              </Tooltip>
            }
            dataIndex="isSentToAgency"
            render={(isSent) => <BooleanIcon boolean={isSent} noError />}
          />
        )}
        <Column title={t("requests.label.actions")} render={(request) => <RequestActionsMenu size="small" hideUnusableButtons selectedRequests={[request]} />} />
      </Table>

      <Button icon={<Icon icon={faFileExcel} />} style={{ top: -40 }} onClick={() => handleDownloadExcel()}>
        {t("requests.button.export_to_excel")}
      </Button>

      <div className={classNames("multi-select-toolbar", { hidden: selectedRequests.length < 2 })}>
        {t("requests.multi_select_toolbar.request_selected", { count: selectedRequests.length })}

        <RequestActionsMenu style={{ marginLeft: 15 }} selectedRequests={selectedRequests} />
      </div>
    </>
  )
}

export default RequestsList
