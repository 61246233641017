import ModalLayout from "@components/Auth/ModalLayout/ModalLayout"
import { Icon } from "@components/Icon"
import { faCheckCircle, faEnvelope, faLock, faPaperPlane, faSmileWink } from "@fortawesome/pro-light-svg-icons"
import { api } from "@services/fetchService.ts"
import { Button, Form, Input, Result } from "antd"
import { useState } from "react"
import { Link } from "react-router-dom"

const ForgotPassword = () => {
  const [isDone, setIsDone] = useState(false)

  const send = (values: { email: string }) => {
    api.auth.forgotPassword({ email: values.email }).then(() => setIsDone(true))
  }

  return (
    <ModalLayout title={isDone ? undefined : "Mot de passe oublié ?"}>
      {isDone ? (
        <Result
          title="C'est fait !"
          subTitle="Si l'adresse que vous nous avez communiquée est enregistrée chez nous, nous vous avons envoyé un mail. Si vous n'avez rien reçu, vérifiez vos spams."
          status="success"
          style={{ paddingTop: 20 }}
          icon={<Icon icon={faCheckCircle} />}
          extra={
            <Link to="/auth/login">
              <Button type="primary" icon={<Icon icon={faLock} />}>
                Retour à l&apos;écran de connexion
              </Button>
            </Link>
          }
        />
      ) : (
        <>
          <p style={{ textAlign: "center" }}>
            Ne vous inquiétez pas. Récupérer son compte est très facile. Donnez-nous l&apos;adresse e-mail avec laquelle vous avez créé votre compte, et nous vous enverrons un lien
            pour enregistrer un nouveau mot de passe <Icon icon={faSmileWink} />
          </p>
          <Form onFinish={send} style={{ marginTop: 24 }}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Veuillez entrer votre adresse e-mail." },
                { type: "email", message: "Veuillez entrer une adresse e-mail valide." }
              ]}
            >
              <Input prefix={<Icon icon={faEnvelope} />} placeholder="E-mail" />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" icon={<Icon icon={faPaperPlane} />}>
                Envoyer
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </ModalLayout>
  )
}

export default ForgotPassword
