import { AgencyApi, AgentApi, AuthApi, AvailabilityApi, Configuration, RequestApi, SelectOptionApi, ServiceCareUnitApi, StatisticsApi } from "@opal/interimeo-openapi/"

const { VITE_API_URL } = import.meta.env

let accessToken = ""

const configuration = new Configuration({
  basePath: VITE_API_URL,
  accessToken: () => accessToken
})

export const api = {
  serviceCareUnit: new ServiceCareUnitApi(configuration),
  selectOption: new SelectOptionApi(configuration),
  request: new RequestApi(configuration),
  auth: new AuthApi(configuration),
  agency: new AgencyApi(configuration),
  agent: new AgentApi(configuration),
  availability: new AvailabilityApi(configuration),
  statistics: new StatisticsApi(configuration)
}

export const setAccessToken = (newAccessToken: string) => (accessToken = newAccessToken)
