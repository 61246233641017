import { FiltersType } from "@components/Agent/AgentsList.tsx"
import { DisableAgentModal } from "@components/Agent/DisabledAgentModal.tsx"
import { NewAgentModal } from "@components/Agent/NewAgentModal.tsx"
import { UpdateAgentModal } from "@components/Agent/UpdateAgentModal.tsx"
import { Agent, AgentType } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService.ts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { message } from "antd"
import { isNil } from "lodash"
import { createContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

interface IAgentsContext {
  filters: FiltersType
  setFilters: (filters: FiltersType) => void
  resetFilters: () => void
  editAgent: (agent?: Agent) => void
  disableAgent: (agent?: Agent) => void
  enableAgent: (agent?: Agent) => void
  openNewAgentModal: () => void
}

interface AgentsProviderProps {
  children: React.ReactNode
}

const AgentsContext = createContext<IAgentsContext>(null!)

const AgentsProvider = ({ children }: AgentsProviderProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [filterText, setFilterText] = useState<string>()
  const [filterType, setFilterType] = useState<AgentType>()
  const [filterDisabled, setFilterDisabled] = useState<boolean>(false)
  const [filterSearch, setFilterSearch] = useState<string>()
  const [isUpdateAgentModalOpen, setIsUpdateAgentModalOpen] = useState<boolean>(false)
  const [isDisableAgentModalOpen, setIsDisableAgentModalOpen] = useState<boolean>(false)
  const [currentAgent, setCurrentAgent] = useState<Agent>()
  const [isNewAgentModalOpen, setIsNewAgentModalOpen] = useState<boolean>(false)

  const enableAgentMutation = useMutation({
    mutationFn: (data: { id: number }) => {
      return api.agent.enableAgent(data.id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["agents"])
      queryClient.invalidateQueries(["requestsStatisticsSider"])
      message.success(t("agent.message.success.enabled_agent"))
    }
  })

  const filters: FiltersType = useMemo(() => {
    const f: FiltersType = {}

    if (filterType) f.type = filterType
    if (filterText) f.text = filterText
    if (filterSearch) f.search = filterSearch

    f.disabled = filterDisabled

    return f
  }, [filterText, filterType, filterDisabled, filterSearch])

  const setFilters = (filters: FiltersType) => {
    if (!isNil(filters.type)) setFilterType(filters.type)
    if (!isNil(filters.text)) setFilterText(filters.text)
    if (!isNil(filters.search)) setFilterSearch(filters.search)

    setFilterDisabled(!isNil(filters.disabled) ? filters.disabled : false)
  }

  const resetFilters = () => {
    setFilterText("")
  }

  const closeAllModal = () => {
    setIsUpdateAgentModalOpen(false)
    setIsNewAgentModalOpen(false)
    setIsDisableAgentModalOpen(false)
  }

  const editAgent = (agent?: Agent) => {
    if (agent) setCurrentAgent(agent)
    setIsUpdateAgentModalOpen(true)
  }

  const disableAgent = (agent?: Agent) => {
    if (agent) setCurrentAgent(agent)
    setIsDisableAgentModalOpen(true)
  }

  const enableAgent = (agent?: Agent) => enableAgentMutation.mutate({ id: agent?.id ?? 0 })

  const openNewAgentModal = () => {
    setIsNewAgentModalOpen(true)
  }

  const exposedValue: IAgentsContext = {
    filters,
    setFilters,
    resetFilters,
    editAgent,
    disableAgent,
    enableAgent,
    openNewAgentModal
  }

  return (
    <AgentsContext.Provider value={exposedValue}>
      {children}
      <NewAgentModal open={isNewAgentModalOpen} onOK={closeAllModal} onCancel={closeAllModal} />
      <UpdateAgentModal open={isUpdateAgentModalOpen} onOK={closeAllModal} onCancel={closeAllModal} agent={currentAgent} />
      <DisableAgentModal open={isDisableAgentModalOpen} onOK={closeAllModal} onCancel={closeAllModal} agent={currentAgent} />
    </AgentsContext.Provider>
  )
}

export { AgentsContext, AgentsProvider }
