import { Icon } from "@components/Icon.tsx"
import AuthContext from "@contexts/AuthContext.tsx"
import { faUserCheck, faUserSlash } from "@fortawesome/pro-light-svg-icons"
import { ShiftValueByTimesType } from "@modules/ShiftValueByTimesType"
import {
  AgentRelations,
  AgentType,
  CareUnit,
  CareUnitShort,
  GetAgents200Response,
  GetAllAgencies200ResponseInner,
  GetStatisticsSider200Response,
  Request,
  SelectOption,
  SelectOptionSelectName,
  Service
} from "@opal/interimeo-openapi"
import { api } from "@services/fetchService"
import { useQuery } from "@tanstack/react-query"
import { isICS, isMobile } from "@utils/utils.ts"
import dayjs from "dayjs"
import { sortBy } from "lodash"
import { createContext, useCallback, useContext, useMemo } from "react"

type Option = {
  value: string
  label: string
  metadata: object
}

type GetCareUnits = {
  serviceId: number
  careUnits: CareUnitShort[]
}

interface ISelectsContext {
  getSelectOption: (value: string) => SelectOption | undefined
  getLabel: (value?: string) => string | undefined
  getActive: (active: boolean) => JSX.Element
  getShiftByRequest: (request: Request) => ShiftValueByTimesType
  getAgencyById: (agencyId: number) => GetAllAgencies200ResponseInner | undefined
  getAgentsByType: (type: AgentType) => AgentRelations[] | undefined
  shifts?: Option[]
  qualifications?: Option[]
  helpTypes?: Option[]
  services?: Service[] | undefined
  agencies?: GetAllAgencies200ResponseInner[]
  getCareUnitsByServiceInCareUnit: (careUnitId: number) => GetCareUnits
  getServiceName: (careUnitId: number) => string
  getCareUnitName: (careUnitId: number) => string
  getCareUnit: (careUnitId: number) => CareUnit | undefined
  careUnits?: CareUnit[]
  statisticsSider?: GetStatisticsSider200Response | undefined
}

interface SelectsProviderProps {
  children: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const SelectsContext = createContext<ISelectsContext>(null!)

const SelectsProvider = (props: SelectsProviderProps) => {
  const { user } = useContext(AuthContext)

  const selectOptions = useQuery({
    queryKey: ["selectOptions"],
    queryFn: () => api.selectOption.getAllSelectOptions()
  })

  const services = useQuery({
    queryKey: ["services"],
    queryFn: () => api.serviceCareUnit.getAllServices()
  })

  const careUnits = useQuery({
    queryKey: ["careUnits"],
    queryFn: () => api.serviceCareUnit.getAllCareUnits()
  })

  const agencies = useQuery({
    queryKey: ["agencies"],
    queryFn: () => api.agency.getAllAgencies()
  })

  const statisticsSider = useQuery({
    queryKey: ["requestsStatisticsSider"],
    queryFn: () => api.statistics.getStatisticsSider()
  })

  const agents = useQuery({
    queryKey: ["agents", { user }],
    queryFn: () => {
      if (user && (isICS(user) || isMobile(user))) {
        return api.agent.getAgents()
      }

      return Promise.resolve({ data: { totalItems: 0, totalPages: 0, items: [] }, isLoading: false }) as unknown as GetAgents200Response
    }
  })

  const sortedServices = useMemo(() => sortBy(services.data, "name"), [services.data])

  const getServiceName = (careUnitId: number): string => {
    const careUnitsFilter = careUnits.data?.filter((c) => c.id === careUnitId)

    if (careUnitsFilter) {
      const serviceId = careUnitsFilter[0].serviceId
      const servicesFilter = services.data?.filter((s) => s.id === serviceId)

      if (servicesFilter) {
        return servicesFilter[0].name
      }
    }

    return ""
  }

  const getCareUnitsByServiceInCareUnit = (careUnitId: number): GetCareUnits => {
    const data: GetCareUnits = { serviceId: 0, careUnits: [] }
    const careUnitsFilter = careUnits.data?.filter((c) => c.id === careUnitId)

    if (careUnitsFilter) {
      data.serviceId = careUnitsFilter[0].serviceId

      const servicesFilter = services.data?.filter((s) => s.id === data.serviceId)

      if (servicesFilter) {
        data.careUnits = servicesFilter[0].careUnits
      }
    }

    return data
  }

  const getCareUnitName = (careUnitId: number): string => {
    const careUnitsFilter = careUnits.data?.filter((c) => c.id === careUnitId)

    return careUnitsFilter?.[0] ? `${careUnitsFilter[0].code} ${careUnitsFilter[0].name}` : ""
  }

  const getCareUnit = (careUnitId: number): CareUnit | undefined => careUnits.data?.find((c) => c.id === careUnitId)

  const getAgencyById = (agencyId: number): GetAllAgencies200ResponseInner | undefined => agencies.data?.filter((a) => a.id === agencyId)[0]

  const getAgentsByType = (type: AgentType): AgentRelations[] | undefined => agents.data?.items?.filter((a) => a.type === type && !a.disabled)

  const getOptions = useCallback(
    (selectName: SelectOptionSelectName): Option[] | undefined => {
      return selectOptions.data
        ?.filter((s) => s.selectName === selectName)
        .sort((a, b) => (a.order || 0) - (b.order || 0))
        .map((s) => ({ value: s.value, label: getTranslation(s) || s.value, metadata: s.metadata || {} }))
    },
    [selectOptions.data]
  )
  const getSelectOption = (value: string): SelectOption | undefined => selectOptions.data?.find((s) => s.value === value)
  const getLabel = (value?: string): string | undefined => (value ? getTranslation(getSelectOption(value)) : "")

  const getActive = (active: boolean): JSX.Element => {
    if (!active) return <Icon icon={faUserCheck} fixedWidth color="green" />

    return <Icon icon={faUserSlash} fixedWidth color="red" />
  }

  const getShiftByRequest = useCallback(
    (request: Request): ShiftValueByTimesType => {
      const label = `${dayjs(request.start).format("H")}h${dayjs(request.start).format("mm")} - ${dayjs(request.end).format("H")}h${dayjs(request.end).format("mm")}`
      const shifts = getOptions(SelectOptionSelectName.SHIFT)
      const shift = shifts?.filter((s) => s.label === label)

      return {
        shiftValue: shift && shift.length > 0 ? shift[0].value : "custom_schedule",
        isCustom: !!(shift && shift.length === 0)
      }
    },
    [getOptions]
  )

  const getTranslation = (s: SelectOption | undefined): string | undefined => {
    if (s) {
      return s.i18n ? (s.i18n as { [index: string]: string })["fr"] : s.value
    }
    return undefined
  }

  const exposedValue: ISelectsContext = {
    getSelectOption,
    getLabel,
    getActive,
    getShiftByRequest,
    shifts: getOptions(SelectOptionSelectName.SHIFT),
    qualifications: getOptions(SelectOptionSelectName.QUALIFICATION),
    helpTypes: getOptions(SelectOptionSelectName.HELP_TYPE),
    services: sortedServices,
    agencies: agencies.data,
    getAgencyById,
    getAgentsByType,
    getCareUnitsByServiceInCareUnit,
    getServiceName,
    getCareUnit,
    getCareUnitName,
    careUnits: careUnits.data,
    statisticsSider: statisticsSider.data
  }

  return <SelectsContext.Provider value={exposedValue}>{props.children}</SelectsContext.Provider>
}

export { SelectsContext, SelectsProvider }
export default SelectsContext
