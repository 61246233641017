import ModalLayout from "@components/Auth/ModalLayout/ModalLayout"
import { Icon } from "@components/Icon"
import AuthContext from "@contexts/AuthContext"
import { faEnvelope, faLock, faSignIn } from "@fortawesome/pro-light-svg-icons"
import { Button, Form, Input } from "antd"
import { useContext } from "react"
import { Link, useNavigate } from "react-router-dom"

const Login = () => {
  const navigate = useNavigate()

  const { isLoading, login } = useContext(AuthContext)

  const onFinishForm = (values: { email: string; password: string }) => {
    login(values.email, values.password)
      .then(() => navigate("/portal"))
      .catch((error) => {
        if (error.response?.json?.code === "auth.email_not_validated") {
          navigate(`/auth/validate-email?email=${values.email}`)
        }
      })
  }

  return (
    <ModalLayout title="Se connecter">
      <Form onFinish={onFinishForm}>
        <Form.Item name="email" rules={[{ required: true, message: "Veuillez entrer votre adresse e-mail." }]}>
          <Input prefix={<Icon icon={faEnvelope} />} placeholder="E-mail" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: "Veuillez entrer votre mot de passe." }]}>
          <Input.Password prefix={<Icon icon={faLock} />} placeholder="Mot de passe" />
        </Form.Item>
        <Form.Item>
          <Link style={{ float: "right" }} to="/auth/forgot-password">
            Mot de passe oublié ?
          </Link>
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button loading={isLoading} type="primary" htmlType="submit" icon={<Icon icon={faSignIn} />}>
            Se connecter
          </Button>
        </Form.Item>
      </Form>
    </ModalLayout>
  )
}

export default Login
