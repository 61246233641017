import { Icon } from "@components/Icon.tsx"
import { faEye } from "@fortawesome/pro-light-svg-icons"
import { RequestRelations } from "@opal/interimeo-openapi"
import { Popover } from "antd"
import { useTranslation } from "react-i18next"

const RequestComments = ({ request }: { request: RequestRelations }) => {
  const { t } = useTranslation()

  return (
    <>
      {request.comment && (
        <Popover content={request.comment} title={t("requests.label.comment.private")} overlayStyle={{ width: 300 }}>
          <Icon icon={faEye} fixedWidth color="blue" />
          &nbsp;
        </Popover>
      )}

      {request.commentPublic && (
        <Popover content={request.commentPublic} title={t("requests.label.comment.public")} overlayStyle={{ width: 300 }}>
          <Icon icon={faEye} fixedWidth />
        </Popover>
      )}
    </>
  )
}

export default RequestComments
