import { Icon } from "@components/Icon"
import { faCheck, faCircleExclamation, faTimes } from "@fortawesome/pro-light-svg-icons"
import { Agent, CancelAvailabilityRequestsRequestInner, RequestRelations } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Alert, Form, Input, message, Modal, Switch } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

type CancelRequestsAssignmentModalProps = {
  open: boolean
  requests?: RequestRelations[]
  onOK: (count: number) => void
  onCancel: () => void
}

export const CancelRequestsAssignmentModal = ({ open, onOK, onCancel, requests }: CancelRequestsAssignmentModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [agent, setAgent] = useState<Agent>()
  const [confirmCreateAvailability, setConfirmCreateAvailability] = useState(false)

  const rejectRequests = useMutation({
    mutationFn: (data: CancelAvailabilityRequestsRequestInner[]) => api.request.cancelAvailabilityRequests(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["requests-cancelled-assignments"])
      queryClient.invalidateQueries(["requests"])
      queryClient.invalidateQueries(["requests-statistics"])
      requests?.forEach((r) => {
        queryClient.invalidateQueries(["request", { id: r.id }])
      })
      onOK(data.length)

      message.success(t("requests.message.success.cancel_assignment", { count: data.length }))
    }
  })

  const [form] = Form.useForm()

  useEffect(() => {
    if (open) {
      form.resetFields()

      if (requests?.length) {
        const affectation = requests[0]?.availabilities.filter((a) => !a.canceledAt)[0]
        if (affectation.agent) setAgent(affectation.agent)
      }

      setConfirmCreateAvailability(false)
    }
  }, [open, form, requests])

  const onFinish = ({ comment }: { comment: string }) => {
    if (requests?.length) rejectRequests.mutate(requests.map((r) => ({ id: r.id, comment, confirmCreateAvailability })))
  }

  return (
    <Modal
      title={
        <>
          <Icon icon={faCircleExclamation} fixedWidth color="orange" /> {t("requests.modal.cancel_assignment.title", { count: requests?.length })}
        </>
      }
      className="reject-request-confirmation-modal"
      open={open}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t("requests.modal.cancel_assignment.button.ok_text", { count: requests?.length })}
      okButtonProps={{ icon: <Icon icon={faTimes} />, loading: rejectRequests.isLoading, danger: true }}
      cancelButtonProps={{ disabled: rejectRequests.isLoading }}
      destroyOnClose={true}
    >
      {rejectRequests.isError && (
        <Alert
          message={t("requests.modal.cancel_assignment.error.message", { count: requests?.length })}
          description={t("requests.modal.cancel_assignment.error.description", { count: requests?.length })}
          type="error"
          showIcon
          style={{ marginTop: 20 }}
        />
      )}
      <Form form={form} onFinish={onFinish} layout="vertical" style={{ marginTop: 20 }} preserve={false}>
        <Form.Item name="comment" label={t("requests.label.comment")}>
          <Input.TextArea />
        </Form.Item>

        <Alert
          key="confirmCreateAvailability"
          type="warning"
          message={t("requests.modal.cancel_assignment.confirm_create_availability")}
          description={
            <>
              {t("requests.modal.cancel_assignment.confirm_create_availability.info", { agentName: `${agent?.lastname} ${agent?.firstname}` })}&nbsp;
              <Switch
                checkedChildren={<Icon icon={faCheck} />}
                unCheckedChildren={<Icon icon={faTimes} />}
                onChange={setConfirmCreateAvailability}
                checked={confirmCreateAvailability}
              />
            </>
          }
          showIcon
        />
      </Form>
    </Modal>
  )
}
