import { Icon } from "@components/Icon.tsx"
import { RequestStatusTag } from "@components/Tags.tsx"
import SelectsContext from "@contexts/SelectsContext.tsx"
import { faArrowsLeftRight, faArrowsToLine, faCheck, faTimes } from "@fortawesome/pro-light-svg-icons"
import { AvailabilityRelations, RequestRelations } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService.ts"
import { useQueryClient } from "@tanstack/react-query"
import { fullnameAgent } from "@utils/utils.ts"
import { Alert, Card, Col, Descriptions, message, Modal, Row, Switch } from "antd"
import dayjs from "dayjs"
import { ReactNode, useContext, useState } from "react"
import { useTranslation } from "react-i18next"

type AssignAvailabilityProps = {
  isVisible: boolean
  onCancel: () => void
  request?: RequestRelations
  availability?: AvailabilityRelations
}

export const AssignAvailabilityModal = ({ isVisible, onCancel, request, availability }: AssignAvailabilityProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { getLabel, getCareUnitName } = useContext(SelectsContext)

  const [createNewRequestOrAvailability, setCreateNewRequestOrAvailability] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  if (!request || !availability) return <></>

  const saveAssignment = () => {
    let newRequestOrAvailability = {}
    if (createNewRequestOrAvailability) {
      const requestStart = dayjs(request.start)
      const requestEnd = dayjs(request.end)
      const availabilityStart = dayjs(availability.start)
      const availabilityEnd = dayjs(availability.end)
      if (!availabilityEnd.isSame(requestEnd, "minutes")) {
        if (availabilityEnd > requestEnd) newRequestOrAvailability = { start: requestEnd, end: availabilityEnd }
        else newRequestOrAvailability = { start: availabilityEnd, end: requestEnd }
      } else {
        if (availabilityStart > requestStart) newRequestOrAvailability = { start: requestStart, end: availabilityStart }
        else newRequestOrAvailability = { start: availabilityStart, end: requestStart }
      }
    }
    api.request
      .confirmOneRequest(request.id, availability.id, newRequestOrAvailability)
      .then(() => {
        queryClient.invalidateQueries(["requests"])
        queryClient.invalidateQueries(["availabilities"])
        setIsLoading(false)
        onCancel()
        message.success("Agent affecté à la demande.")
      })
      .catch(() => {
        setIsLoading(false)
        message.error(t("errors.an_unknown_error_has_occurred"))
      })
  }

  /**
   * Check if assignment possible
   */
  const alerts: ReactNode[] = []
  let canAssign = true
  const requestStart = dayjs(request.start)
  const requestEnd = dayjs(request.end)
  const availabilityStart = dayjs(availability.start)
  const availabilityEnd = dayjs(availability.end)

  if (requestStart >= availabilityEnd || availabilityStart >= requestEnd) {
    alerts.push(
      <Alert
        key="noOverlap"
        type="error"
        message="Il n'y a pas d'horaire commun."
        description="La demande n'a pas de durée en commun avec la disponibilité de l'agent choisie."
        style={{ marginBottom: 15 }}
        showIcon
      />
    )
    canAssign = false
  } else if (!availabilityEnd.isSame(requestEnd, "minutes") || !availabilityStart.isSame(requestStart, "minutes")) {
    alerts.push(
      <Alert
        key="availabilityGreater"
        type="warning"
        message="La demande n'est pas parfaitement compatible avec la disponibilité de l'agent."
        description={
          <>
            Voulez-vous créer une nouvelle disponibilité/demande avec la durée restante ?
            <Switch
              style={{ marginLeft: 7 }}
              checkedChildren={<Icon icon={faCheck} />}
              unCheckedChildren={<Icon icon={faTimes} />}
              onChange={setCreateNewRequestOrAvailability}
              checked={createNewRequestOrAvailability}
            />
            <br />
            Par exemple, si la demande est plus longue que la disponibilité de l&apos;agent, une nouvelle demande &quot;résiduelle&quot; peut être créée pour l&apos;assigner à un
            autre agent.
          </>
        }
        style={{ marginBottom: 15 }}
        showIcon
      />
    )
  }

  return (
    <Modal
      title={
        <div style={{ marginBottom: 20 }}>
          <Icon icon={faArrowsToLine} fixedWidth /> {t("requests.modal.assign_availability.title")}
        </div>
      }
      className="reject-request-confirmation-modal"
      open={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={saveAssignment}
      okText={t("requests.modal.assign_availability.button.ok_text")}
      okButtonProps={{ icon: <Icon icon={faCheck} />, loading: isLoading, disabled: !canAssign }}
      cancelButtonProps={{ disabled: false }}
      destroyOnClose={true}
      width={750}
    >
      {alerts}
      <Row gutter={[25, 25]} style={{ marginTop: 25 }} align="middle">
        <Col span={10}>
          <Card size="small" title={`Demande #${request.id}`} extra={<RequestStatusTag status={request.status} />}>
            <Descriptions column={1} size="small">
              <Descriptions.Item label={t("requests.label.care_unit")}>{getCareUnitName(request.careUnitId)}</Descriptions.Item>
              <Descriptions.Item label={t("requests.label.date")}>{dayjs(request.start).format("L")}</Descriptions.Item>
              <Descriptions.Item label={t("requests.label.schedule")}>{`${dayjs(request.start).format("LT")} - ${dayjs(request.end).format("LT")}`}</Descriptions.Item>
              <Descriptions.Item label={t("requests.label.time_off")}>{`${request.timeOff} ${t("requests.label.time_off.minutes_short")}`}</Descriptions.Item>
              <Descriptions.Item label={t("requests.label.qualification")}>{getLabel(request.qualificationValue || undefined)}</Descriptions.Item>
              <Descriptions.Item label={t("requests.label.type")}>{getLabel(request.helpTypeValue || undefined)}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={4} style={{ textAlign: "center" }}>
          <Icon icon={faArrowsLeftRight} size="4x" />
        </Col>
        <Col span={10}>
          <Card size="small" title="Disponibilité">
            <Descriptions column={1} size="small">
              <Descriptions.Item label={t("availabilities.label.agent")}>{fullnameAgent(availability.agent)}</Descriptions.Item>
              <Descriptions.Item label={t("requests.label.qualification")}>{getLabel(availability.agent.qualificationValue || undefined)}</Descriptions.Item>
              <Descriptions.Item label={t("requests.label.date")}>{dayjs(availability.start).format("L")}</Descriptions.Item>
              <Descriptions.Item label={t("requests.label.schedule")}>{`${dayjs(availability.start).format("LT")} - ${dayjs(availability.end).format("LT")}`}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </Modal>
  )
}
