import "./RequestDetails.less"

import { Icon } from "@components/Icon"
import { RequestStatusTag } from "@components/Tags"
import AuthContext from "@contexts/AuthContext"
import SelectsContext from "@contexts/SelectsContext"
import { faArrowProgress, faCheck, faCheckDouble, faClock, faLock, faPaperPlane, faPencil, faSpinnerThird, faTimes, faTrash, faUser } from "@fortawesome/pro-light-svg-icons"
import { RequestActionMetadata } from "@modules/RequestActionMetadata"
import { ActionWithUser, Agent, Availability, CareUnit, Request, RequestUpdate } from "@opal/interimeo-openapi"
import { getCascadeColor } from "@services/cascadeService.tsx"
import { api } from "@services/fetchService"
import { useQuery } from "@tanstack/react-query"
import { fullnameAgent, fullnameOrCaret, isICS } from "@utils/utils"
import { Descriptions, Drawer, Spin, Tag, Timeline, TimelineItemProps, Tooltip } from "antd"
import dayjs from "dayjs"
import { TFunction } from "i18next"
import { sortBy } from "lodash"
import { useContext } from "react"
import { useTranslation } from "react-i18next"

type RequestDetailsProps = {
  open: boolean
  request: Request
  onClose: () => void
}

type UpdateRequestDetailsProps = {
  oldValues: RequestUpdate & { careUnit: CareUnit } & { availabilities: Availability[] }
  newValues: RequestUpdate & { careUnit: CareUnit } & { availabilities: Availability[] }
  request: Request
}

const UpdateRequestDetails = ({ oldValues, newValues, request }: UpdateRequestDetailsProps) => {
  const { t } = useTranslation()
  const { getLabel, getCareUnitName, getServiceName } = useContext(SelectsContext)

  if (!oldValues || !newValues) return <></>

  return (
    <Descriptions size="small" column={1} className="update-request-details">
      {newValues.careUnitId && oldValues.careUnitId && getServiceName(newValues.careUnitId) !== getServiceName(oldValues.careUnitId) && (
        <Descriptions.Item label={t("requests.label.service")}>
          {getServiceName(oldValues.careUnitId)} &rarr; {getServiceName(newValues.careUnitId)}
        </Descriptions.Item>
      )}
      {newValues.careUnitId && oldValues.careUnitId && (
        <Descriptions.Item label={t("requests.label.care_unit")}>
          {getCareUnitName(oldValues.careUnitId)} &rarr; {getCareUnitName(newValues.careUnitId)}
        </Descriptions.Item>
      )}
      {newValues.start && !dayjs(newValues.start).isSame(dayjs(oldValues.start), "day") && (
        <Descriptions.Item label={t("requests.label.date")}>
          {dayjs(oldValues.start).format("L")} &rarr; {dayjs(newValues.start).format("L")}
        </Descriptions.Item>
      )}
      {((newValues.start && dayjs(newValues.start).format("LT") !== dayjs(oldValues.start).format("LT")) ||
        (newValues.end && dayjs(newValues.end).format("LT") !== dayjs(oldValues.end).format("LT"))) && (
        <Descriptions.Item label={t("requests.label.schedule")}>
          {dayjs(oldValues.start || request.start).format("LT")} - {dayjs(oldValues.end || request.end).format("LT")} &rarr; {dayjs(newValues.start || request.start).format("LT")}{" "}
          - {dayjs(newValues.end || request.end).format("LT")}
        </Descriptions.Item>
      )}
      {newValues.timeOff && (
        <Descriptions.Item label={t("requests.label.time_off")}>
          {`${oldValues.timeOff} ${t("requests.label.time_off.minutes_short")}`} &rarr; {`${newValues.timeOff} ${t("requests.label.time_off.minutes_short")}`}
        </Descriptions.Item>
      )}
      {newValues.helpTypeValue && oldValues.helpTypeValue && (
        <Descriptions.Item label={t("requests.label.type")}>
          {getLabel(oldValues.helpTypeValue)} &rarr; {getLabel(newValues.helpTypeValue)}
        </Descriptions.Item>
      )}
      {newValues.qualificationValue && oldValues.qualificationValue && (
        <Descriptions.Item label={t("requests.label.qualification")}>
          {getLabel(oldValues.qualificationValue)} &rarr; {getLabel(newValues.qualificationValue)}
        </Descriptions.Item>
      )}
      {newValues.comment && oldValues.comment && (
        <Descriptions.Item label={t("requests.label.comment.private")}>
          {oldValues.comment} &rarr; {newValues.comment}
        </Descriptions.Item>
      )}
      {newValues.commentPublic && oldValues.commentPublic && (
        <Descriptions.Item label={t("requests.label.comment.public")}>
          {oldValues.commentPublic} &rarr; {newValues.commentPublic}
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}

const getTimelineItem = (action: ActionWithUser, request: Request, t: TFunction): TimelineItemProps => {
  const item: TimelineItemProps = {
    label: dayjs(action.date).format("DD/MM/YY - HH:mm")
  }
  switch (action.type) {
    case "CREATE_REQUEST":
      item.children = t("requests.details.request_created_by", { user: fullnameOrCaret(action.user) })
      break
    case "UPDATE_REQUEST":
      item.children = (
        <>
          {t("requests.details.request_updated_by", { user: fullnameOrCaret(action.user) })}
          <br />
          <UpdateRequestDetails request={request} {...(action.metadata as RequestActionMetadata)} />
        </>
      )
      item.dot = <Icon icon={faPencil} />
      break
    case "CANCEL_REQUEST":
      item.children = (
        <>
          {t("requests.details.request_canceled_by", { user: fullnameOrCaret(action.user) })}
          <br />
          <span className="secondary-info">{(action.metadata as { [key: string]: string }).comment}</span>
        </>
      )
      item.dot = <Icon icon={faTrash} color="red" />
      break
    case "CANCEL_AVAILABILITY":
      item.children = (
        <>
          {t("requests.details.request_canceled_availability_by", { user: fullnameOrCaret(action.user) })}
          <br />
          <span className="secondary-info">{(action.metadata as { [key: string]: string }).comment}</span>
        </>
      )
      item.dot = <Icon icon={faCheck} color={"green"} />
      break
    case "ACCEPT_REQUEST":
      item.children = t("requests.details.request_accepted_by", { user: fullnameOrCaret(action.user) })
      item.dot = <Icon icon={faCheck} color={"green"} />
      break
    case "CONFIRMED_REQUEST":
      item.children = t("requests.details.request_agent_confirmed_by", {
        user: fullnameOrCaret(action.user),
        agent: fullnameAgent((action.metadata as { [key: string]: Agent }).agent)
      })
      item.dot = <Icon icon={faCheckDouble} color={"green"} />
      break
    case "REJECT_REQUEST":
      item.children = (
        <>
          {t("requests.details.request_rejected_by", { user: fullnameOrCaret(action.user) })}
          <br />
          <span className="secondary-info">{(action.metadata as { [key: string]: string }).comment}</span>
        </>
      )
      item.dot = <Icon icon={faTimes} color="red" />
      break
    case "SEND_AGENCY_REQUEST":
      item.children = t("requests.details.request_sent_interim_by", { user: fullnameOrCaret(action.user) })
      item.dot = <Icon icon={faPaperPlane} />
      break
    case "START_CASCADE":
      item.children = t("requests.details.request_start_cascade_by", { user: fullnameOrCaret(action.user) })
      item.dot = <Icon icon={faArrowProgress} />
      break
  }
  return item
}

const RequestDetails = ({ open, request, onClose }: RequestDetailsProps) => {
  const { t } = useTranslation()
  const { getLabel, getAgencyById } = useContext(SelectsContext)
  const { user } = useContext(AuthContext)

  const requestQuery = useQuery({
    queryKey: ["request", { id: request.id }],
    queryFn: () => api.request.getOneRequest(request.id)
  })

  const requestData = requestQuery.data

  const affectation = requestData?.availabilities.filter((a) => !a.canceledAt)[0]
  const agencyAccess = sortBy(requestData?.agencyAccess, "accessDate")

  return (
    <Drawer
      title={t("requests.details.details_request_id", { id: request.id })}
      extra={
        <>
          {requestData?.isSentToAgency && user && isICS(user) && (
            <Tooltip title={t("requests.label.is_sent_to_agency.tooltip")}>
              <Tag color="blue">
                <Icon icon={faPaperPlane} />
              </Tag>
            </Tooltip>
          )}
          {requestData?.status && <RequestStatusTag status={requestData.status} />}
        </>
      }
      placement="right"
      open={open}
      onClose={onClose}
      width={500}
      destroyOnClose
      className="request-details-drawer"
    >
      <Spin spinning={requestQuery.isLoading}>
        <Descriptions bordered size="small" column={1} className="request-details-list">
          <Descriptions.Item label={t("requests.label.service")}>{requestData?.careUnit.service.name}</Descriptions.Item>
          <Descriptions.Item label={t("requests.label.care_unit")}>
            {requestData?.careUnit.code} {requestData?.careUnit.name}
          </Descriptions.Item>
          <Descriptions.Item label={t("requests.label.cost_center")}>{requestData?.costCenter}</Descriptions.Item>
          <Descriptions.Item label={t("requests.label.date")}>{requestData?.start && dayjs(requestData.start).format("L")}</Descriptions.Item>
          <Descriptions.Item label={t("requests.label.schedule")}>
            {requestData?.start && requestData?.end && (
              <>
                {dayjs(requestData.start).format("LT")} - {dayjs(requestData.end).format("LT")}
              </>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={t("requests.label.time_off")}>
            {requestData?.timeOff ? `${requestData?.timeOff} ${t("requests.label.time_off.minutes_short")}` : "-"}
          </Descriptions.Item>
          <Descriptions.Item label={t("requests.label.type")}>{requestData?.helpTypeValue && getLabel(requestData.helpTypeValue)}</Descriptions.Item>
          <Descriptions.Item label={t("requests.label.qualification")}>{requestData?.qualificationValue && getLabel(requestData.qualificationValue)}</Descriptions.Item>
          <Descriptions.Item label={t("requests.label.comment.private")}>{requestData?.comment || "-"}</Descriptions.Item>
          <Descriptions.Item label={t("requests.label.comment.public")}>{requestData?.commentPublic || "-"}</Descriptions.Item>
        </Descriptions>
        {affectation && affectation.agent && (
          <>
            <h3>
              <Icon icon={faUser} fixedWidth /> Agent affecté
            </h3>
            <Descriptions bordered size="small" column={1} className="request-details-list">
              <Descriptions.Item label="Nom">{fullnameAgent(affectation.agent)}</Descriptions.Item>
              <Descriptions.Item label={t("requests.label.qualification")}>
                {affectation.agent.qualificationValue && getLabel(affectation.agent.qualificationValue)}
              </Descriptions.Item>
              <Descriptions.Item label="Type">{affectation.agent.type}</Descriptions.Item>
              <Descriptions.Item label="Agence">{affectation.agent.agencyId ? getAgencyById(affectation.agent.agencyId)?.name : "-"}</Descriptions.Item>
              <Descriptions.Item label={t("requests.label.date")}>{affectation.start && dayjs(affectation.start).format("L")}</Descriptions.Item>
              <Descriptions.Item label={t("requests.label.schedule")}>
                {affectation.start && affectation.end && (
                  <>
                    {dayjs(affectation.start).format("LT")} - {dayjs(affectation.end).format("LT")}
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={t("requests.label.comment")}>{affectation.comment || "-"}</Descriptions.Item>
            </Descriptions>{" "}
          </>
        )}
        {agencyAccess.length > 0 && (
          <>
            <h3>
              <Icon icon={faLock} fixedWidth /> Accès agences intérim {agencyAccess[0].cascade ? "(Cascade)" : ""}
            </h3>
            <Timeline
              className="request-details-timeline"
              mode="left"
              style={{ marginTop: 25, marginBottom: -45 }}
              items={agencyAccess?.map((access) => {
                const accessDate = dayjs(access.accessDate)
                return {
                  label: accessDate.format("DD/MM/YY - HH:mm"),
                  color: accessDate.isSameOrAfter(dayjs()) ? "#d9d9d9" : getCascadeColor(access.cascade),
                  children: access.cancelledDate ? (
                    <>
                      <s style={{ color: "red" }}>{access.agency?.name}</s> (révoqué le {dayjs(access.cancelledDate).format("DD/MM/YY - HH:mm")})
                    </>
                  ) : (
                    access.agency?.name
                  ),
                  style: accessDate.isSameOrAfter(dayjs()) ? { color: "#bfbfbf" } : {},
                  dot: accessDate.isSameOrBefore(dayjs()) && !access.isAccesible ? <Icon icon={faSpinnerThird} spin /> : undefined
                }
              })}
            />
          </>
        )}
        <h3>
          <Icon icon={faClock} fixedWidth />
          Historique
        </h3>
        <Timeline
          className="request-details-timeline"
          mode="left"
          style={{ marginTop: 25 }}
          items={requestData?.actions.map((action) => getTimelineItem(action, requestData, t))}
        />
      </Spin>
    </Drawer>
  )
}

export default RequestDetails
