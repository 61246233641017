import "./SendInterimRequestsModal.less"

import { Icon } from "@components/Icon"
import { CareUnitTag } from "@components/Tags.tsx"
import { RequestsContext } from "@contexts/RequestsContext.tsx"
import SelectsContext from "@contexts/SelectsContext.tsx"
import { faArrowProgress, faPaperPlane, faPlay } from "@fortawesome/pro-light-svg-icons"
import { Request, SendInterimMultipleRequestsRequest } from "@opal/interimeo-openapi"
import { getRequestCascade, getRequestCascadeTag } from "@services/cascadeService.tsx"
import { api } from "@services/fetchService"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Alert, Button, Card, Col, Form, message, Modal, Popconfirm, Row, Select, Table } from "antd"
import dayjs from "dayjs"
import { sortBy } from "lodash"
import { useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
const { Column } = Table

type SendInterimRequestsModalProps = {
  open: boolean
  requests?: Request[]
  onOK: (count: number) => void
  onCancel: () => void
}

export const SendInterimRequestsModal = ({ open, onOK, onCancel, requests }: SendInterimRequestsModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { setSelectedRequests } = useContext(RequestsContext)
  const { agencies, getLabel, getCareUnit } = useContext(SelectsContext)

  const sendInterimRequestsMutation = useMutation({
    mutationFn: (data: SendInterimMultipleRequestsRequest) => api.request.sendInterimMultipleRequests(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["requests"])
      queryClient.invalidateQueries(["requests-statistics"])
      requests?.forEach((r) => {
        queryClient.invalidateQueries(["request", { id: r.id }])
      })
      onOK(data.totalItems)
      setSelectedRequests([])

      message.success(t("requests.message.success.send_interim_requests", { count: data.totalItems }))
    }
  })

  const [form] = Form.useForm()

  useEffect(() => {
    if (open) form.resetFields()
  }, [open, form, requests])

  const sortedRequests = useMemo(() => sortBy(requests, "start"), [requests])
  const agenciesToManualSend = useMemo(() => agencies?.filter((a) => a.manualSend) || [], [agencies])

  const onFinish = (values: { agencyId: number }) => {
    if (requests?.length) sendInterimRequestsMutation.mutate({ ...values, ids: requests.map((r) => r.id) })
  }

  const startCascade = () => {
    if (requests?.length) sendInterimRequestsMutation.mutate({ ids: requests.map((r) => r.id) })
  }

  return (
    <Modal
      title={
        <>
          <Icon icon={faPaperPlane} fixedWidth /> {t("requests.modal.send_interim_request.title", { count: requests?.length })}
        </>
      }
      className="send-interim-request-modal"
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose={true}
      width={1200}
    >
      {sendInterimRequestsMutation.isError && (
        <Alert
          message={t("requests.modal.send_interim_request.error.message", { count: requests?.length })}
          description={t("requests.modal.send_interim_request.error.description", { count: requests?.length })}
          type="error"
          showIcon
          style={{ marginTop: 20 }}
        />
      )}

      <Row style={{ marginTop: 25 }} gutter={24}>
        <Col flex="3" className="requests-to-send-column">
          <Card
            title={
              <>
                <Icon icon={faArrowProgress} fixedWidth /> Envoi en cascade
              </>
            }
            size="small"
          >
            <Table
              size="small"
              dataSource={sortedRequests}
              rowKey="id"
              className="requests-to-send-table"
              pagination={false}
              rowClassName={(record) => "cascade-" + getRequestCascade(record)}
            >
              <Column render={getRequestCascadeTag} />
              <Column title={t("requests.label.care_unit")} dataIndex="careUnitId" render={(careUnitId) => <CareUnitTag careUnit={getCareUnit(careUnitId)} />} />
              <Column title={t("requests.label.date")} render={(r) => dayjs(r.start).format("L")} />
              <Column title={t("requests.label.schedule")} render={(r) => `${dayjs(r.start).format("LT")} - ${dayjs(r.end).format("LT")}`} width="100px" />
              <Column title={t("requests.label.type")} dataIndex="helpTypeValue" render={getLabel} />
              <Column title={t("requests.label.qualification")} dataIndex="qualificationValue" render={getLabel} />
            </Table>
            <Popconfirm title={t("misc.confirm.are_you_sure")} onConfirm={startCascade}>
              <Button type="primary" loading={sendInterimRequestsMutation.isLoading} icon={<Icon icon={faPlay} fixedWidth />} style={{ marginTop: 25 }}>
                {t("requests.button.send_requests_interim_cascade", { count: requests?.length })}
              </Button>
            </Popconfirm>
          </Card>
        </Col>
        {agenciesToManualSend.length > 0 && (
          <Col flex="2">
            <Card
              title={
                <>
                  <Icon icon={faPaperPlane} fixedWidth /> Envoi manuel
                </>
              }
              size="small"
            >
              <Alert type="warning" showIcon message="Attention ! Un envoi manuel ne passera pas par Randstad." style={{ marginBottom: 25 }} />
              <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item name="agencyId" label="Choix de l'agence" rules={[{ required: true }]}>
                  <Select>
                    {agenciesToManualSend.map((a) => (
                      <Select.Option key={a.id} value={a.id}>
                        {a.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Popconfirm title={t("misc.confirm.are_you_sure")} onConfirm={form.submit}>
                  <Button type="primary" loading={sendInterimRequestsMutation.isLoading} icon={<Icon icon={faPaperPlane} fixedWidth />}>
                    {t("requests.button.send_requests_interim", { count: requests?.length })}
                  </Button>
                </Popconfirm>
              </Form>
            </Card>
          </Col>
        )}
      </Row>
    </Modal>
  )
}
