import ModalLayout from "@components/Auth/ModalLayout/ModalLayout"
import { Icon } from "@components/Icon"
import { faCheckCircle, faLock, faPaperPlane } from "@fortawesome/pro-light-svg-icons"
import { api } from "@services/fetchService.ts"
import { Button, Form, Input, Result } from "antd"
import { useState } from "react"
import { Link, useLocation } from "react-router-dom"

import Error500 from "./Error500"

const ResetPassword = () => {
  const [isDone, setIsDone] = useState(false)

  const code = new URLSearchParams(useLocation().search).get("code")

  if (!code) {
    return <Error500 />
  }

  const send = (values: { password: string }) => {
    api.auth.resetPassword({ code, password: values.password }).then(() => setIsDone(true))
  }

  return (
    <ModalLayout title={!isDone ? "Changez votre mot de passe" : undefined}>
      {isDone ? (
        <Result
          title="C'est fait !"
          subTitle="Votre mot de passe est modifié. Connectez-vous à présent avec votre nouveau mot de passe."
          status="success"
          style={{ paddingTop: 20 }}
          icon={<Icon icon={faCheckCircle} />}
          extra={
            <Link to="/auth/login">
              <Button type="primary" icon={<Icon icon={faLock} />}>
                Aller à l&apos;écran de connexion
              </Button>
            </Link>
          }
        />
      ) : (
        <>
          <p style={{ textAlign: "center" }}>Entrez un nouveau mot de passe pour votre compte.</p>
          <Form onFinish={send} style={{ marginTop: 24 }}>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Veuillez entrer votre nouveau mot de passe" },
                { min: 8, message: "Le mot de passe doit faire au minimum 8 carractères." }
              ]}
            >
              <Input.Password prefix={<Icon icon={faLock} />} placeholder="Nouveau mot de passe" />
            </Form.Item>
            <Form.Item
              name="confirm-password"
              dependencies={["password"]}
              rules={[
                { required: true, message: "Veuillez confirmer votre nouveau mot de passe" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) return Promise.resolve()
                    return Promise.reject(new Error("Les deux mots de passe ne correspondent pas."))
                  }
                })
              ]}
            >
              <Input.Password prefix={<Icon icon={faLock} />} placeholder="Confirmez le mot de passe" />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" icon={<Icon icon={faPaperPlane} />}>
                Envoyer
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </ModalLayout>
  )
}

export default ResetPassword
