import "./RequestStatusCard.less"

import { Card, Col, Row, Statistic } from "antd"
import classNames from "classnames"

export type AntDesignColor = string[] & {
  primary?: string | undefined
}

type RequestStatusCardProps = {
  title: string
  value: number | string
  color: AntDesignColor | string
  selected?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler
}

const RequestStatusCard = ({ title, value, color, selected = false, disabled = false, onClick }: RequestStatusCardProps) => {
  const stringColor = typeof color === "string" ? color : color.primary

  return (
    <Card size="small" className={classNames("request-status-card", { selected, disabled })} onClick={onClick}>
      <Row gutter={10}>
        <Col>
          <div className="colored-bar" style={{ backgroundColor: stringColor }}></div>
        </Col>
        <Col>
          <Statistic title={title} value={value} />
        </Col>
      </Row>
    </Card>
  )
}

export default RequestStatusCard
