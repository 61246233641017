import { CareUnitShort, Service } from "@opal/interimeo-openapi"
import { RadioChangeEvent } from "antd"
import { FormInstance } from "antd/es/form/hooks/useForm"

export const handleChangeCareUnit = (careUnitId: number, careUnits: CareUnitShort[], form: FormInstance) => {
  if (careUnits.length > 0) {
    const careUnitsFilter = careUnits.filter((c) => c.id === careUnitId)

    if (careUnitsFilter.length > 0 && careUnitsFilter[0].costCenter.length > 0) {
      form.setFieldValue("costCenter", careUnitsFilter[0].costCenter[0])
    }
  }
}

export const handleChangeService = (serviceId: number, services: Service[] | undefined, form: FormInstance, setCareUnits: (careUnits: CareUnitShort[]) => void) => {
  const service = services?.filter((s) => s.id === serviceId)

  if (service) {
    const careUnits = service[0].careUnits

    form.setFieldsValue({
      serviceId: service[0].id,
      careUnitId: careUnits[0].id
    })

    if (careUnits[0].costCenter.length > 0) {
      form.setFieldValue("costCenter", careUnits[0].costCenter[0])
    }

    setCareUnits(careUnits)
  }
}

export const handleChangeShifts = (event: RadioChangeEvent, setUseCustomSchedule: (use: boolean) => void) => {
  const { target } = event

  setUseCustomSchedule(target.value === "custom_schedule")
}
