import { Icon } from "@components/Icon"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faCheck, faCheckDouble, faCheckSquare, faHourglass, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons"
import { RequestStatus, UserRoles } from "@opal/interimeo-openapi"
import { Tag, Tooltip } from "antd"
import { useTranslation } from "react-i18next"

type RequestStatusProps = {
  status: RequestStatus
  onlyIcon?: boolean
}

const statusData: { [RequestStatus: string]: { icon: IconDefinition; color: string; text: string } } = {
  [RequestStatus.WAITING]: { icon: faHourglass, color: "gold", text: "requests.status.waiting" },
  [RequestStatus.ACCEPTED]: { icon: faCheck, color: "blue", text: "requests.status.accepted" },
  [RequestStatus.REJECTED]: { icon: faTimes, color: "volcano", text: "requests.status.reject" },
  [RequestStatus.CONFIRMED]: { icon: faCheckDouble, color: "green", text: "requests.status.confirmed" },
  [RequestStatus.DONE]: { icon: faCheckSquare, color: "lime", text: "requests.status.done" },
  [RequestStatus.CANCELLED]: { icon: faTrash, color: "red", text: "requests.status.canceled" }
}

export const RequestStatusTag = ({ status, onlyIcon = false }: RequestStatusProps) => {
  const data = statusData[status]
  const { t } = useTranslation()

  if (onlyIcon) {
    return (
      <Tooltip title={t(data.text)}>
        <Tag color={data.color} bordered={false}>
          <Icon icon={data.icon} size={"lg"} />
        </Tag>
      </Tooltip>
    )
  }

  return (
    <Tag color={data.color} icon={<Icon icon={data.icon} />}>
      {t(data.text)}
    </Tag>
  )
}

export const RoleTag = ({ role }: { role: UserRoles }) => {
  switch (role) {
    case UserRoles.IC:
      return <Tag color="blue">IC</Tag>
    case UserRoles.ICS:
      return <Tag color="green">ICS</Tag>
    case UserRoles.MOBILE:
      return <Tag color="gold">Mobile</Tag>
    default:
      return <></>
  }
}

export const CareUnitTag = ({ careUnit }: { careUnit: { code: string; name: string } | undefined }) =>
  careUnit && (
    <Tooltip title={careUnit.name}>
      <Tag>{careUnit.code}</Tag>
    </Tooltip>
  )
