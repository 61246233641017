import { Icon, InfoIcon } from "@components/Icon"
import SelectsContext from "@contexts/SelectsContext"
import { faCalendarCirclePlus, faPencil, faSave } from "@fortawesome/pro-light-svg-icons"
import { CustomShiftsType } from "@modules/CustomShiftsType"
import { FormValuesType } from "@modules/FormValuesType"
import { CareUnitShort, Request, RequestUpdate } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { handleChangeCareUnit, handleChangeService, handleChangeShifts } from "@utils/requestFormUtils.ts"
import { getDateTimeBySelectOption } from "@utils/utils.ts"
import { DatePicker, Form, Input, InputNumber, message, Modal, Radio, Select, TimePicker, Tooltip } from "antd"
import dayjs from "dayjs"
import { omit } from "lodash"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

type NewRequestModalProps = {
  open: boolean
  request?: Request
  onOK: (request: Request) => void
  onCancel: () => void
}

export const UpdateRequestModal = ({ open, onOK, onCancel, request }: NewRequestModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [useCustomSchedule, setUseCustomSchedule] = useState(false)

  const updateRequest = useMutation({
    mutationFn: (data: { id: number; request: RequestUpdate }) => {
      return api.request.updateRequest(data.id, data.request)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["requests"])
      message.success(t("requests.message.success.update_request"))
      onOK(data)
    }
  })

  const { getSelectOption, getShiftByRequest, shifts, qualifications, helpTypes, services, getCareUnitsByServiceInCareUnit } = useContext(SelectsContext)

  const [form] = Form.useForm()
  const [careUnits, setCareUnits] = useState<CareUnitShort[]>([])

  useEffect(() => {
    if (open) form.resetFields()
    if (open && request) {
      const shiftValueByTimesType = getShiftByRequest(request)
      const serviceInCareUnit = getCareUnitsByServiceInCareUnit(request.careUnitId)

      setCareUnits(serviceInCareUnit.careUnits)
      setUseCustomSchedule(shiftValueByTimesType.isCustom)

      form.setFieldsValue({
        ...request,
        date: dayjs(request.start),
        shiftValue: shiftValueByTimesType.shiftValue,
        timeStart: dayjs(request.start),
        timeEnd: dayjs(request.end),
        serviceId: serviceInCareUnit.serviceId,
        careUnitId: request.careUnitId
      })
    }
  }, [open, form, request, getShiftByRequest, getCareUnitsByServiceInCareUnit])

  const save = (formValues: FormValuesType) => {
    const valuesClone = { ...formValues }
    const date = dayjs(formValues.date)
    const selectOption = getSelectOption(formValues.shiftValue)
    const times: CustomShiftsType = {
      start: valuesClone.timeStart,
      end: valuesClone.timeEnd
    }

    omit(valuesClone, ["date", "timeStart", "timeEnd"])

    const requestUpdate: RequestUpdate = {
      ...valuesClone,
      start: getDateTimeBySelectOption(date, selectOption, times, "start"),
      end: getDateTimeBySelectOption(date, selectOption, times, "end")
    }

    request && updateRequest.mutate({ id: request.id, request: requestUpdate })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onValuesChange = (changedValues: any, allValues: any) => {
    if ((changedValues.shiftValue || changedValues.timeStart || changedValues.timeEnd || changedValues.date) && allValues.date) {
      const shift = allValues.shiftValue !== "custom_shift" ? getSelectOption(allValues.shiftValue) : undefined
      const customTimes = {
        start: allValues.timeStart,
        end: allValues.timeEnd
      }
      const start = getDateTimeBySelectOption(allValues.date, shift, customTimes, "start")
      const end = getDateTimeBySelectOption(allValues.date, shift, customTimes, "end")
      const hours = dayjs(end).diff(start, "hours", true)
      if (hours <= 7) form.setFieldValue("timeOff", 0)
      if (hours > 7) form.setFieldValue("timeOff", 30)
      if (hours >= 11) form.setFieldValue("timeOff", 60)
    }
  }

  return (
    <Modal
      title={
        <>
          <Icon icon={faPencil} fixedWidth /> {t("requests.title.edit_request")}
        </>
      }
      className="update-request-modal"
      open={open}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t("requests.button.register")}
      okButtonProps={{ icon: <Icon icon={faSave} />, loading: updateRequest.isLoading }}
      width={600}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={save}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{ marginTop: 30 }}
        requiredMark={false}
        labelWrap
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="serviceId"
          label={
            <>
              {t("requests.label.service")}&nbsp;
              <InfoIcon tooltip={t("requests.label.service.after.tooltip")} />
              &nbsp;
            </>
          }
          rules={[{ required: true }]}
        >
          <Select onChange={(value) => handleChangeService(value, services, form, setCareUnits)} disabled>
            {services?.map((s) => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="careUnitId"
          label={
            <>
              {t("requests.label.care_unit")}&nbsp;
              <InfoIcon tooltip={t("requests.label.care_unit.after.tooltip")} />
              &nbsp;
            </>
          }
          rules={[{ required: true }]}
        >
          <Select onChange={(value) => handleChangeCareUnit(value, careUnits, form)} disabled>
            {careUnits?.map((s) => (
              <Select.Option key={s.id} value={s.id}>
                {s.code} {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          hidden
          name="costCenter"
          label={
            <>
              {t("requests.label.cost_center")}&nbsp;
              <InfoIcon tooltip={t("requests.label.cost_center.after.tooltip")} />
              &nbsp;
            </>
          }
          rules={[{ required: true }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item name="date" label={t("requests.label.date")} rules={[{ required: true }]}>
          <DatePicker style={{ width: "100%" }} format="L" changeOnBlur />
        </Form.Item>

        <Form.Item name="shiftValue" label={t("requests.label.schedule")} rules={[{ required: true }]}>
          <Radio.Group buttonStyle="solid" onChange={(event) => handleChangeShifts(event, setUseCustomSchedule)}>
            {shifts?.map((s) => (
              <Radio.Button key={s.value} value={s.value}>
                {s.label}
              </Radio.Button>
            ))}

            <Tooltip title={t("requests.button.custom_schedule")}>
              <Radio.Button key="custom_schedule" value="custom_schedule">
                <Icon icon={faCalendarCirclePlus} />
              </Radio.Button>
            </Tooltip>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={t("requests.label.custom_schedule")} hidden={!useCustomSchedule}>
          <Form.Item
            name="timeStart"
            rules={[{ required: useCustomSchedule, message: t("requests.required.message.the_field_is_required", { field: t("requests.placeholder.start_schedule") }) }]}
            noStyle
          >
            <TimePicker
              showSecond={false}
              format={"HH:mm"}
              placeholder={t("requests.placeholder.start_schedule")}
              minuteStep={5}
              disabled={!useCustomSchedule}
              style={{ width: "38%" }}
              changeOnBlur
            />
          </Form.Item>
          &nbsp;-&nbsp;
          <Form.Item
            name="timeEnd"
            rules={[{ required: useCustomSchedule, message: t("requests.required.message.the_field_is_required", { field: t("requests.placeholder.end_schedule") }) }]}
            noStyle
          >
            <TimePicker
              showSecond={false}
              format={"HH:mm"}
              placeholder={t("requests.placeholder.end_schedule")}
              minuteStep={5}
              disabled={!useCustomSchedule}
              style={{ width: "38%" }}
              changeOnBlur
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label={t("requests.label.time_off")}>
          <Form.Item name="timeOff" rules={[{ required: true, message: t("requests.required.message.the_field_is_required", { field: t("requests.label.time_off") }) }]} noStyle>
            <InputNumber min={0} step={5} />
          </Form.Item>
          &nbsp;({t("requests.label.time_off.minutes")})
        </Form.Item>

        <Form.Item name="helpTypeValue" label={t("requests.label.type")} rules={[{ required: true }]}>
          <Radio.Group buttonStyle="solid">
            {helpTypes?.map((s) => (
              <Radio.Button key={s.value} value={s.value}>
                {s.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item name="qualificationValue" label={t("requests.label.qualification")} rules={[{ required: true }]}>
          <Radio.Group buttonStyle="solid">
            {qualifications?.map((s) => (
              <Radio.Button key={s.value} value={s.value}>
                {s.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item name="comment" label={t("requests.label.comment.private")}>
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>

        <Form.Item
          name="commentPublic"
          label={
            <>
              {t("requests.label.comment.public")} <InfoIcon tooltip={t("requests.label.comment.public.tooltip")} />
            </>
          }
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
