import { Icon } from "@components/Icon"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { Button, Popconfirm, Tooltip } from "antd"
import classNames from "classnames"
import { MouseEvent, MouseEventHandler } from "react"

type TableButtonProps = {
  icon: IconDefinition
  onClick: () => void
  type?: "danger" | "success"
  tooltip?: string | JSX.Element
  confirm?: string | true
  loading?: boolean
  disabled?: false
}

export const TableButton = ({ onClick, confirm, icon, type, tooltip, loading = false, disabled = false }: TableButtonProps) => {
  const _onClick = (e: MouseEvent | undefined) => {
    onClick()
    e?.stopPropagation()
  }

  const onStop: MouseEventHandler = (e) => e.stopPropagation()

  const _icon = <Icon icon={icon} fixedWidth />
  const _danger = type === "danger"
  const _confirmMessage = confirm === true ? "Êtes-vous sûr ?" : confirm
  let button

  if (confirm) {
    button = (
      <Button
        className={classNames("table-button", { "button-success": type === "success" })}
        shape="circle"
        size="small"
        icon={_icon}
        danger={_danger}
        loading={loading}
        disabled={disabled}
      />
    )
  } else {
    button = (
      <Button
        className={classNames("table-button", { "button-success": type === "success" })}
        shape="circle"
        size="small"
        icon={_icon}
        danger={_danger}
        loading={loading}
        disabled={disabled}
        onClick={_onClick}
      />
    )
  }

  if (tooltip) {
    button = <Tooltip title={tooltip}>{button}</Tooltip>
  }

  if (_confirmMessage) {
    button = (
      <>
        {/* eslint-disable-next-line */}
        <span onClick={onStop}>
          <Popconfirm title={_confirmMessage} onConfirm={(e) => _onClick(e)}>
            {button}
          </Popconfirm>
        </span>
      </>
    )
  }

  return button
}
