import "@components/Requests/Requests.less"

import { AssignAgentToRequestModal } from "@components/AssignAgentToRequestModal/AssignAgentToRequestModal.tsx"
import { CancelRequestsAssignmentModal } from "@components/CancelRequestsAssignmentModal.tsx"
import { CancelRequestsModal } from "@components/CancelRequestsModal.tsx"
import { NewRequestModal } from "@components/NewRequestModal/NewRequestModal.tsx"
import { RejectRequestsModal } from "@components/RejectRequestsModal.tsx"
import RequestDetails from "@components/RequestDetails/RequestDetails.tsx"
import { SendInterimRequestsModal } from "@components/SendInterimRequestsModal/SendInterimRequestsModal.tsx"
import { UpdateRequestModal } from "@components/UpdateRequestModal.tsx"
import { Request, RequestCreate, RequestFromJSON, RequestRelations, RequestStatus } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService.ts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { message } from "antd"
import { createContext, Dispatch, SetStateAction, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocalStorage } from "react-use"

export type FiltersType = {
  status: RequestStatus[]
  careUnits: number[]
  services: number[]
  start?: string
  end?: string
  sendInterim: string
}

interface IRequestsContext {
  filters: FiltersType
  setFilters: (filters: FiltersType) => void
  resetFilters: () => void
  openNewRequestModal: (date?: Date) => void
  openRequestDetails: (request: Request) => void
  editRequest: (request: Request) => void
  acceptRequests: (request?: Request) => void
  rejectRequests: (request?: Request) => void
  cancelRequests: (request?: Request) => void
  sendInterimRequests: (request?: Request) => void
  assignAgentToRequest: (request: Request) => void
  cancelRequestAssignment: (request?: Request) => void
  selectedRequests: RequestRelations[]
  setSelectedRequests: Dispatch<SetStateAction<RequestRelations[]>>
  requestsToInsert: RequestCreate[] | undefined
  setRequestsToInsert: Dispatch<SetStateAction<RequestCreate[] | undefined>>
}

interface RequestsProviderProps {
  children: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const RequestsContext = createContext<IRequestsContext>(null!)

const RequestsProvider = ({ children }: RequestsProviderProps) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const [requestsToInsert, setRequestsToInsert] = useLocalStorage<RequestCreate[]>("requestsToInsert", [], {
    raw: false,
    serializer: JSON.stringify,
    deserializer: (json) => JSON.parse(json).map(RequestFromJSON)
  })

  const [filterStatus, setFilterStatus] = useState<RequestStatus[]>([])
  const [filterCareUnits, setFilterCareUnits] = useState<number[]>([])
  const [filterServices, setFilterServices] = useState<number[]>([])
  const [filterStart, setFilterStart] = useState<string>()
  const [filterEnd, setFilterEnd] = useState<string>()
  const [filterSendInterim, setFilterSendInterim] = useState("all")

  const filters: FiltersType = useMemo(() => {
    const ret: FiltersType = { status: [...filterStatus], careUnits: [...filterCareUnits], services: [...filterServices], sendInterim: filterSendInterim }
    if (filterStart) ret.start = filterStart
    if (filterEnd) ret.end = filterEnd
    return ret
  }, [filterStatus, filterCareUnits, filterServices, filterStart, filterEnd, filterSendInterim])

  const [isNewRequestModalOpen, setIsNewRequestModalOpen] = useState<boolean>(false)
  const [isUpdateRequestModalOpen, setIsUpdateRequestModalOpen] = useState<boolean>(false)
  const [isCancelRequestModalOpen, setIsCancelRequestModalOpen] = useState<boolean>(false)
  const [isRejectRequestModalOpen, setIsRejectRequestModalOpen] = useState<boolean>(false)
  const [isAssignAgentToRequestModalOpen, setIsAssignAgentToRequestModalOpen] = useState<boolean>(false)
  const [isSendInterimRequestsModalOpen, setIsSendInterimRequestsModalOpen] = useState<boolean>(false)
  const [isRequestDetailsOpen, setIsRequestDetailsOpen] = useState<boolean>(false)
  const [isCancelRequestsAssignmentModalOpen, setIsCancelRequestsAssignmentModalOpen] = useState<boolean>(false)

  const [currentRequest, setCurrentRequest] = useState<Request>()
  const [selectedRequests, setSelectedRequests] = useState<RequestRelations[]>([])

  const [newRequestInitialDate, setNewRequestInitialDate] = useState<Date>()

  const acceptRequestsMutation = useMutation({
    mutationFn: (ids: number[]) => api.request.acceptMultipleRequests({ ids }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["requests"])
      queryClient.invalidateQueries(["requestsStatisticsSider"])
      queryClient.invalidateQueries(["requests-statistics"])
      selectedRequests?.forEach((r) => {
        queryClient.invalidateQueries(["request", { id: r.id }])
      })
      setSelectedRequests([])

      message.success(t("requests.message.success.accept_requests", { count: data.totalItems }))
    }
  })

  const setFilters = (filters: FiltersType) => {
    setFilterStatus(filters.status)
    setFilterServices(filters.services)
    setFilterCareUnits(filters.careUnits)
    setFilterStart(filters.start)
    setFilterEnd(filters.end)
    setFilterSendInterim(filters.sendInterim)
  }

  const resetFilters = () => {
    setFilterStatus([])
    setFilterServices([])
    setFilterCareUnits([])
    setFilterStart(undefined)
    setFilterEnd(undefined)
    setFilterSendInterim("all")
  }

  const editRequest = (request: Request) => {
    setCurrentRequest(request)
    setIsUpdateRequestModalOpen(true)
  }

  const closeAllModal = () => {
    setIsNewRequestModalOpen(false)
    setIsUpdateRequestModalOpen(false)
    setIsCancelRequestModalOpen(false)
    setIsRejectRequestModalOpen(false)
    setIsAssignAgentToRequestModalOpen(false)
    setIsSendInterimRequestsModalOpen(false)
    setIsCancelRequestsAssignmentModalOpen(false)
    setNewRequestInitialDate(undefined)
    if (!isRequestDetailsOpen) {
      setCurrentRequest(undefined)
    }
  }

  const openRequestDetails = (request: Request) => {
    setCurrentRequest(request)
    setIsRequestDetailsOpen(true)
  }

  const closeRequestDetails = () => {
    setCurrentRequest(undefined)
    setIsRequestDetailsOpen(false)
  }

  const cancelRequests = (request?: Request) => {
    if (request) setCurrentRequest(request)
    setIsCancelRequestModalOpen(true)
  }

  const acceptRequests = (request?: Request) => acceptRequestsMutation.mutate(request ? [request.id] : selectedRequests.map((r) => r.id))

  const sendInterimRequests = (request?: Request) => {
    if (request) setCurrentRequest(request)
    setIsSendInterimRequestsModalOpen(true)
  }

  const assignAgentToRequest = (request: Request) => {
    setCurrentRequest(request)
    setIsAssignAgentToRequestModalOpen(true)
  }

  const cancelRequestAssignment = (request?: Request) => {
    if (request) setCurrentRequest(request)
    setIsCancelRequestsAssignmentModalOpen(true)
  }

  const rejectRequests = (request?: Request) => {
    if (request) setCurrentRequest(request)
    setIsRejectRequestModalOpen(true)
  }

  const openNewRequestModal = (date?: Date) => {
    setNewRequestInitialDate(date)
    setIsNewRequestModalOpen(true)
  }

  const exposedValue: IRequestsContext = {
    filters,
    setFilters,
    resetFilters,
    openNewRequestModal,
    openRequestDetails,
    editRequest,
    acceptRequests,
    rejectRequests,
    cancelRequests,
    sendInterimRequests,
    assignAgentToRequest,
    cancelRequestAssignment,
    selectedRequests,
    setSelectedRequests,
    requestsToInsert,
    setRequestsToInsert
  }

  return (
    <RequestsContext.Provider value={exposedValue}>
      {children}
      <NewRequestModal open={isNewRequestModalOpen} onOK={closeAllModal} onCancel={closeAllModal} initialDate={newRequestInitialDate} />
      <AssignAgentToRequestModal open={isAssignAgentToRequestModalOpen} onOK={closeAllModal} onCancel={closeAllModal} request={currentRequest} />
      <UpdateRequestModal open={isUpdateRequestModalOpen} onOK={closeAllModal} onCancel={closeAllModal} request={currentRequest} />
      <CancelRequestsModal open={isCancelRequestModalOpen} onOK={closeAllModal} onCancel={closeAllModal} requests={currentRequest ? [currentRequest] : selectedRequests} />
      <RejectRequestsModal open={isRejectRequestModalOpen} onOK={closeAllModal} onCancel={closeAllModal} requests={currentRequest ? [currentRequest] : selectedRequests} />
      <CancelRequestsAssignmentModal
        open={isCancelRequestsAssignmentModalOpen}
        onOK={closeAllModal}
        onCancel={closeAllModal}
        // TODO check this !
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requests={currentRequest ? [currentRequest] : selectedRequests}
      />
      <SendInterimRequestsModal
        open={isSendInterimRequestsModalOpen}
        onOK={closeAllModal}
        onCancel={closeAllModal}
        requests={currentRequest ? [currentRequest] : selectedRequests}
      />
      {currentRequest && <RequestDetails open={isRequestDetailsOpen} request={currentRequest} onClose={closeRequestDetails} />}
    </RequestsContext.Provider>
  )
}

export { RequestsContext, RequestsProvider }
