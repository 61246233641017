import AgentActionsMenu from "@components/Agent/AgentActionsMenu.tsx"
import { AgentsContext } from "@contexts/AgentsContext.tsx"
import SelectsContext from "@contexts/SelectsContext.tsx"
import { AgentType } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService.ts"
import { useQuery } from "@tanstack/react-query"
import { Table } from "antd"
import { TablePaginationConfig } from "antd/es/table"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useMap } from "react-use"

export type FiltersType = {
  text?: string
  type?: AgentType
  disabled?: boolean
  search?: string
}

const { Column } = Table

const AgentsList = () => {
  const { t } = useTranslation()
  const [pagination, { set: setPagination }] = useMap<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  })

  const { filters } = useContext(AgentsContext)
  const { getLabel, getActive } = useContext(SelectsContext)

  // Reset current pagination to 1 when filters change
  useEffect(() => {
    setPagination("current", 1)
  }, [filters, setPagination])

  const { data, isLoading } = useQuery({
    queryKey: ["agents", { pagination: { current: pagination.current, pagesize: pagination.pageSize }, filters }],
    queryFn: () => api.agent.getAgents(pagination.current, pagination.pageSize, filters)
  })

  // Set pagination total items when data changes
  useEffect(() => {
    if (pagination.total !== data?.totalItems && data?.totalItems !== undefined) {
      setPagination("total", data.totalItems)
    }
  }, [data?.totalItems, pagination, setPagination])

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    if (pagination.current !== newPagination.current) setPagination("current", newPagination.current)
    if (pagination.pageSize !== newPagination.pageSize) setPagination("pageSize", newPagination.pageSize)
  }

  return (
    <>
      <Table dataSource={data?.items} pagination={pagination} rowKey="id" loading={isLoading} size="small" rowClassName="row-clickable" onChange={handleTableChange}>
        <Column title="N°" dataIndex="id" />
        <Column title={t("agent.label.firstname")} dataIndex="firstname" />
        <Column title={t("agent.label.lastname")} dataIndex="lastname" />
        <Column title={t("agent.label.email")} dataIndex="email" />
        <Column title={t("agent.label.qualificationValue")} dataIndex="qualificationValue" render={getLabel} />
        <Column title={t("agent.label.active")} dataIndex="disabled" render={getActive} />
        <Column title={t("agent.label.actions")} render={(agent) => <AgentActionsMenu size="small" selectedAgent={agent} />} />
      </Table>
    </>
  )
}

export default AgentsList
