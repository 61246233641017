import { Icon } from "@components/Icon"
import { RequestsContext } from "@contexts/RequestsContext.tsx"
import { faCircleExclamation, faTrash } from "@fortawesome/pro-light-svg-icons"
import { CancelMultipleRequestsRequest, Request, RequestCreate } from "@opal/interimeo-openapi"
import { api } from "@services/fetchService"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Alert, Form, Input, message, Modal, Switch } from "antd"
import dayjs from "dayjs"
import { pick, sortBy } from "lodash"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

type CancelRequestsModalProps = {
  open: boolean
  requests?: Request[]
  onOK: (count: number) => void
  onCancel: () => void
}

export const CancelRequestsModal = ({ open, onOK, onCancel, requests }: CancelRequestsModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { setRequestsToInsert, openNewRequestModal } = useContext(RequestsContext)

  const [cancelRequestInfo, setCancelRequestInfo] = useState(false)

  const cancelRequests = useMutation({
    mutationFn: (data: CancelMultipleRequestsRequest) => api.request.cancelMultipleRequests(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["requests"])
      queryClient.invalidateQueries(["requestsStatisticsSider"])
      queryClient.invalidateQueries(["requests-statistics"])

      requests?.forEach((r) => {
        queryClient.invalidateQueries(["request", { id: r.id }])
      })

      onOK(data.totalItems)

      message.success(t("requests.message.success.cancel_requests", { count: data.totalItems }))

      if (cancelRequestInfo && requests?.length) {
        setCancelRequestInfo(false)

        const requestsClone: RequestCreate[] = requests.map((r) => {
          const data = pick(r, "careUnitId", "costCenter", "end", "helpTypeValue", "qualificationValue", "start", "timeOff")
          return { ...data, id: dayjs(r.start).valueOf() + Math.random() * 12 }
        })
        setRequestsToInsert(sortBy(requestsClone, "start"))

        openNewRequestModal()
      }
    }
  })

  const [form] = Form.useForm()

  useEffect(() => {
    if (open) {
      form.resetFields()
      setCancelRequestInfo(false)
    }
  }, [open, form, requests])

  const onFinish = (values: { comment: string }) => {
    if (requests?.length) cancelRequests.mutate({ ...values, ids: requests.map((r) => r.id) })
  }

  return (
    <Modal
      title={
        <>
          <Icon icon={faCircleExclamation} fixedWidth color="orange" /> {t("requests.modal.cancel_request.title", { count: requests?.length })}
        </>
      }
      className="cancel-request-confirmation-modal"
      open={open}
      maskClosable={false}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t("requests.modal.cancel_request.button.ok_text", { count: requests?.length })}
      okButtonProps={{ icon: <Icon icon={faTrash} />, loading: cancelRequests.isLoading, danger: true }}
      cancelButtonProps={{ disabled: cancelRequests.isLoading }}
      destroyOnClose={true}
    >
      {requests?.length && requests[0].isSentToAgency && (
        <Alert
          message={t("requests.modal.cancel_request.sent_email_agency.message")}
          description={t("requests.modal.cancel_request.sent_email_agency.description")}
          type="warning"
          showIcon
          style={{ marginTop: 20 }}
        />
      )}

      <Alert
        key="cancelRequestInfo"
        type="info"
        message={
          <>
            {t("requests.modal.cancel_request.sentence.1", { count: requests?.length })}
            <Switch
              style={{ marginLeft: 7 }}
              checkedChildren={t("agent.label.yes")}
              unCheckedChildren={t("agent.label.no")}
              onChange={(cancelRequest) => setCancelRequestInfo(cancelRequest)}
              checked={cancelRequestInfo}
            />
          </>
        }
        description={
          <>
            {t("requests.modal.cancel_request.sentence.2", { count: requests?.length })}&nbsp;
            {t("requests.modal.cancel_request.sentence.3")}
          </>
        }
        style={{ marginTop: 20 }}
        showIcon
      />

      {cancelRequests.isError && (
        <Alert
          message={t("requests.modal.cancel_request.error.message", { count: requests?.length })}
          description={t("requests.modal.cancel_request.error.description", { count: requests?.length })}
          type="error"
          showIcon
          style={{ marginTop: 20 }}
        />
      )}

      <Form form={form} onFinish={onFinish} layout="vertical" style={{ marginTop: 20 }}>
        <Form.Item name="comment" label={t("requests.label.comment", { count: requests?.length })}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}
