import "./Requests.less"

import { Icon } from "@components/Icon.tsx"
import RequestFilters from "@components/Requests/RequestFilters.tsx"
import RequestsCalendar from "@components/RequestsCalendar/RequestsCalendar.tsx"
import RequestsList from "@components/RequestsList/RequestsList.tsx"
import RequestsStatistics from "@components/RequestsStatistics.tsx"
import { RequestsContext } from "@contexts/RequestsContext.tsx"
import { faCalendar, faList, faPlus } from "@fortawesome/pro-light-svg-icons"
import { Button, Card, Col, Divider, Row, Segmented, Space, Typography } from "antd"
import { useContext, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

const { Title } = Typography

enum View {
  List = "list",
  Calendar = "calendar"
}

const views = [
  {
    label: <Trans i18nKey={"requests.views.list"} />,
    value: View.List,
    icon: <Icon icon={faList} />
  },
  {
    label: <Trans i18nKey={"requests.views.calendar"} />,
    value: View.Calendar,
    icon: <Icon icon={faCalendar} />
  }
]

const Requests = () => {
  const { t } = useTranslation()
  const { openNewRequestModal } = useContext(RequestsContext)
  const [view, setView] = useState<string | number>(views[0].value)

  return (
    <>
      <Row justify={"space-between"} style={{ marginBottom: 15 }}>
        <Col>
          <Title level={4}>
            <Icon icon={faList} fixedWidth /> {t("requests.title")}
          </Title>
        </Col>
        <Col>
          <Space size={"middle"}>
            <RequestFilters useDate={true} />

            <Button className="table-add-data-button" type="primary" icon={<Icon icon={faPlus} />} onClick={() => openNewRequestModal()}>
              {t("requests.button.new_requests")}
            </Button>
          </Space>
        </Col>
      </Row>
      <Card>
        <Row gutter={[35, 20]}>
          <Col>
            <RequestsStatistics />
          </Col>
          <Col flex="auto"></Col>
          <Col>
            <Segmented value={view} options={views} onChange={(value) => setView(value)} />
          </Col>
        </Row>

        <Divider />

        {view === View.List && <RequestsList />}

        {view === View.Calendar && <RequestsCalendar />}
      </Card>
    </>
  )
}

export default Requests
