import { Icon } from "@components/Icon.tsx"
import AuthContext from "@contexts/AuthContext.tsx"
import { RequestsContext } from "@contexts/RequestsContext.tsx"
import { faCheck, faPaperPlane, faPencil, faTimes, faTimesSquare, faTrash, faUserPlus, faUserSlash } from "@fortawesome/pro-light-svg-icons"
import { RequestRelations, RequestStatus } from "@opal/interimeo-openapi"
import { isIC, isICS, isMobile } from "@utils/utils.ts"
import { Button, Popconfirm, Space, Tooltip } from "antd"
import { CSSProperties, MouseEvent, useContext } from "react"
import { useTranslation } from "react-i18next"

type RequestActionsMenuProps = {
  size?: "small" | "middle" | "large"
  style?: CSSProperties
  hideUnusableButtons?: boolean
  selectedRequests: RequestRelations[]
}
const RequestActionsMenu = ({ size = "middle", style, hideUnusableButtons = false, selectedRequests }: RequestActionsMenuProps) => {
  const { t } = useTranslation()

  const { user } = useContext(AuthContext)
  const { setSelectedRequests, editRequest, acceptRequests, rejectRequests, cancelRequests, sendInterimRequests, assignAgentToRequest, cancelRequestAssignment } =
    useContext(RequestsContext)

  const stopPropagation = (onClick?: () => void) => (e: MouseEvent | undefined) => {
    if (onClick) onClick()
    e?.stopPropagation()
  }

  const disabledCancelRequests = () => {
    // Disable request suppression if IC user and request confirmed
    if (user && isIC(user) && selectedRequests.filter((r) => r.status === RequestStatus.CONFIRMED).length) return true

    return !!selectedRequests.filter((r) => r.status === RequestStatus.CANCELLED).length
  }

  const disabledCancelAssignment = () => {
    const status = !!selectedRequests.filter((r) => r.status !== RequestStatus.CONFIRMED).length

    if (selectedRequests[0]?.isSentToAgency) return true

    return status
  }

  return (
    <Space.Compact style={style} size={size}>
      {user && (isICS(user) || isMobile(user)) && (
        <>
          {(!hideUnusableButtons || !selectedRequests.filter((r) => r.status !== RequestStatus.WAITING).length) && (
            <Tooltip title={t("requests.button.validate_requests", { count: selectedRequests.length })} placement="bottom">
              <Popconfirm
                title={t("requests.multi_select_toolbar.confirm.are_you_sure")}
                onConfirm={stopPropagation(() => acceptRequests(selectedRequests.length === 1 ? selectedRequests[0] : undefined))}
                onCancel={stopPropagation()}
              >
                <Button
                  icon={<Icon icon={faCheck} fixedWidth color="green" />}
                  disabled={!!selectedRequests.filter((r) => r.status !== RequestStatus.WAITING).length}
                  onClick={stopPropagation()}
                />
              </Popconfirm>
            </Tooltip>
          )}

          {(!hideUnusableButtons || !selectedRequests.filter((r) => r.status !== RequestStatus.WAITING).length) && (
            <Tooltip title={t("requests.button.reject_requests", { count: selectedRequests.length })} placement="bottom">
              <Button
                icon={<Icon icon={faTimes} fixedWidth color="red" />}
                onClick={stopPropagation(() => rejectRequests(selectedRequests.length === 1 ? selectedRequests[0] : undefined))}
                disabled={!!selectedRequests.filter((r) => r.status !== RequestStatus.WAITING).length}
              />
            </Tooltip>
          )}

          {(!hideUnusableButtons || !selectedRequests.filter((r) => (r.status !== RequestStatus.WAITING && r.status !== RequestStatus.ACCEPTED) || r.isSentToAgency).length) && (
            <Tooltip title={t("requests.button.send_requests_interim", { count: selectedRequests.length })} placement="bottom">
              <Button
                icon={<Icon icon={faPaperPlane} fixedWidth />}
                onClick={stopPropagation(() => sendInterimRequests(selectedRequests.length === 1 ? selectedRequests[0] : undefined))}
                disabled={!!selectedRequests.filter((r) => (r.status !== RequestStatus.WAITING && r.status !== RequestStatus.ACCEPTED) || r.isSentToAgency).length}
              />
            </Tooltip>
          )}

          {(!hideUnusableButtons || !selectedRequests.filter((r) => r.status !== RequestStatus.WAITING && r.status !== RequestStatus.ACCEPTED).length) &&
            selectedRequests.length === 1 && (
              <Tooltip title={t("requests.button.assign_agent")} placement="bottom">
                <Button
                  icon={<Icon icon={faUserPlus} fixedWidth />}
                  onClick={stopPropagation(() => assignAgentToRequest(selectedRequests[0]))}
                  disabled={!!selectedRequests.filter((r) => r.status !== RequestStatus.WAITING && r.status !== RequestStatus.ACCEPTED).length}
                />
              </Tooltip>
            )}
          {(!hideUnusableButtons || !selectedRequests.filter((r) => r.status !== RequestStatus.CONFIRMED).length) && (
            <Tooltip title={t("requests.button.remove_assignment")} placement="bottom">
              <Button
                icon={<Icon icon={faUserSlash} fixedWidth />}
                disabled={disabledCancelAssignment()}
                onClick={stopPropagation(() => cancelRequestAssignment(selectedRequests.length === 1 ? selectedRequests[0] : undefined))}
              />
            </Tooltip>
          )}
        </>
      )}
      {(!hideUnusableButtons ||
        (!selectedRequests.filter((r) => r.status === RequestStatus.CANCELLED).length &&
          selectedRequests.length === 1 &&
          selectedRequests[0].status !== RequestStatus.CONFIRMED &&
          !selectedRequests[0].isSentToAgency)) && (
        <Tooltip title={t("requests.button.edit_to_request")} placement="bottom">
          <Button icon={<Icon icon={faPencil} fixedWidth />} onClick={stopPropagation(() => editRequest(selectedRequests[0]))} />
        </Tooltip>
      )}
      {(!hideUnusableButtons || !selectedRequests.filter((r) => r.status === RequestStatus.CANCELLED).length) && (
        <Tooltip title={t("requests.button.cancel_requests", { count: selectedRequests.length })} placement="bottom">
          <Button
            icon={<Icon icon={faTrash} fixedWidth color="red" />}
            onClick={stopPropagation(() => cancelRequests(selectedRequests.length === 1 ? selectedRequests[0] : undefined))}
            disabled={disabledCancelRequests()}
          />
        </Tooltip>
      )}
      {selectedRequests.length >= 2 && (
        <Tooltip title={t("misc.deselect")} placement="bottom">
          <Button icon={<Icon icon={faTimesSquare} fixedWidth />} onClick={stopPropagation(() => setSelectedRequests([]))} />
        </Tooltip>
      )}
    </Space.Compact>
  )
}

export default RequestActionsMenu
